import React from 'react';
import '../skrApp.css';

import { List, ArrowLeftSquare, PlusSquare } from 'react-bootstrap-icons';

// import SKR_Client from './skr-client.js';

const RPA_HOST = "http://127.0.0.1:8080"
const ENDPOINT_RPA_CONVERSATIONS  = "/rpa/conversations/" 

const AGENT_ID = "skr-web-agent-001"

class SideBar extends React.Component {
  constructor(props) {
    super(props);

    console.debug("SIDEBAR Props (constructor)", this.props)

    this.agent_id = (this.props.agent_id) ? this.props.agent_id : AGENT_ID;
    
    // Get SKR_Client intialized for messaging
    this.client = this.props.client

    console.debug(">>>>>>> HOST PARAMS SET >>>>>>>>>", this.agent_id)

    this.on_newHandler = this.props.on_new;

    this.on_convHandler = this.props.on_getConv;

    this.state = {
      isTransitionComplete: true,
      isMobile: false,
      history: [],
    };

    // intialize SKR_Client and get conversations
    this.isSidebarOpen = this.props.isSidebarOpen;  // false for mobile, true for desktop
    this.isLoading = false;
    this.isDataLoaded = false;
    
  }

  componentDidMount() {
    window.addEventListener('resize', this.handleResize);
    this.handleResize();
    if (this.agent_id && !this.isLoading) { 
      this.isLoading = true;
      this.get_conversations_list() }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  get_conversations_list = async () => {
    // Get the conversation from the RPA API
    // @app.get("/iskr/{agent_id}/{cluster_id}")
    if (this.agent_id === null) { console.debug("SIDEBAR: agent_id required to get conversations") }
    const end_point = `${ENDPOINT_RPA_CONVERSATIONS}${this.agent_id}`
    this.client.rpa_get(end_point, "GET_CONVERSATIONS", this.handleResponse)
  }

  set_conversations(response) {
    // Filters out duplicate conversations and lists them from most recent conversation
    const data = response.data.conversations;
    
    // Sort by UTC
    const sortedData = data.sort((a, b) => {
      const dateA = new Date(a.UTC);
      const dateB = new Date(b.UTC);
      return dateB - dateA; // For descending order
    });
    
    // Filter out duplicates
    const sortedConversations = sortedData.reduce((acc, curr) => {
      if (!acc.find(item => item.cluster_id === curr.cluster_id)) {
        acc.push(curr);
      }
      return acc;
    }, []);
        
    // Set the conversation history
    this.setState({history: sortedConversations})
  }

  handleResponse = (response, msg_id) => {
    // Handles the inboud message and sets the message container 
    // for next conversation turn
    // console.debug(">>>>>>> HANDLE RESPONSE >>>>>>>>>", response, msg_id);
    if (response.status !== 200) { console.debug("CLIENT: ERROR: ", response); return }
    else if (msg_id === "GET_CONVERSATIONS") { 
      this.set_conversations(response)
      this.isLoading = false;
      this.isDataLoaded = true

     }

  };

  handleResize = () => {
    const isMobile = window.innerWidth <= 768;
    // this.setState({ isSidebarOpen: true });
  }

  handleTransitionEnd = () => {
    this.setState( { isTransitionComplete: true });
  };

  toggleSidebar = () => {
    console.debug('>>>>>>>>>>SIDEBAR: toggled sidebar ...')

    this.setState(prevState => ({
      isTransitionComplete: false
    }));
    this.props.toggleSidebar();
  }

  render_history() {
    const { isTransitionComplete } = this.state;

    if (!isTransitionComplete || !this.state.history){
      return (<div></div>);
    } 
    else {
      return(
        <div>
          <ul className="history">
          {this.state.history.map((conv) => 
            <li 
              className="history-title" 
              onClick={() => this.on_convClicked(conv)}
              style={{ cursor: 'pointer' }} 
              key={conv.conv_id}> 
              {conv.title} 
            </li>)}
          </ul>
        </div>
      );  
    }
  }

  on_convClicked = (conv) => {
      // Call the callback function passed from the parent component
      console.debug("TRIGGERED CONVERSATION CLICK ... ", conv)
      this.toggleSidebar();
      this.props.onConversationClick(conv);
    }

  // Used as callback handler for new conversation event
  // Allows for any pre-work before calling the main handler
  new_conversation = () => {
    // Add call backs to main NAVS
    console.debug("TRIGGERED NEW CONVERSATION EVENT ... ")
    this.toggleSidebar();
    this.props.on_new();
  }

  openUserControls() {
    console.debug("TRIGGEREDOPEN USER CONTROLS ... ")
    // Add call backs to main NAVS
  }

  render_new_skr() {
    const { isTransitionComplete } = this.state;
    if (!isTransitionComplete){ return (<div></div>); }
    console.debug("RENDERING NEW SKR BUTTON ... ")
    return (
      <div className="new-skr" onClick={this.new_conversation}>
        <button className="side-bar-new-skr" type="button" >
          <PlusSquare/>
        </button>
        <span className="new-skr-title">New seekr</span>
      </div>
    );
  }

  render_user_info() {
    const { isTransitionComplete } = this.state;
    if (!isTransitionComplete){ return (<div></div>); }
    return (
      <div className="user-info" onClick={this.openUserControls}>
          <nav>
            <span className="new-skr-title">KFM   ...</span>
          </nav>
      </div>
    );
  }

  render_close() {
    console.debug("RENDERING CLOSE BUTTON ... ")
    return (
      <button className="side-bar-toggle-collapse" type="button" onClick={this.toggleSidebar}>
        <ArrowLeftSquare />
      </button>
    );
  }
    
  render_loading() {
    console.debug("RENDERING Loading BUTTON ... ")
    return (
      <div> <span className="new-skr-title">Loading Conversations ...</span> </div>
      )
  }

  render_hamburger() {
    return (
      <button className="side-bar-toggle-hamburger" type="button" onClick={this.toggleSidebar}>
        <List />
      </button>
    );
  }

  render() {
    console.debug(">>>>>>>>>>>> RENDER SIDEBAR >>> state:", this.isSidebarOpen)
    if (this.isSidebarOpen && !this.isDataLoaded) { return (<div> {this.render_loading()} </div>); }

    // Load sidebar if its data is loaded and the sidebar is open
    if (this.isSidebarOpen && this.isDataLoaded) {
      console.debug("******* RENDERING SIDEBAR:", this.state )
      return (
      <div>
        <section className={`side-bar ${this.isSidebarOpen ? '' : 'collapsed'}`} onTransitionEnd={this.handleTransitionEnd}>
          {this.render_new_skr()} 
          {this.render_history()}
          {this.render_user_info()}
          {this.isSidebarOpen ? this.render_close(): ''}
        </section>
      </div>
    );}
  }
}
export default SideBar;

