
import React from 'react';
import {Helmet} from "react-helmet";

import './skrApp.css';
import SideBar from './components/skr-side-bar.js';
import Messaging from './components/skr-messenger';
import SwipeViewer from './components/skr-swipe-viewer.js';
import Search from './components/skr-search.js';
import './assets/styles/skr_comp_styles.css';
import skr_hamburger from "./assets/icons/skr-hamburger.png"
import skr_marker from "./assets/icons/skr-mark-up@3x.png"
import skr_marker_lg from "./assets/branding/skr-marker@3x.png"

import SKR_Client from './components/skr-client.js';
import skr_logo from "./assets/branding/skr-logo-mark-dark-mode.png"
import {SKR_WIDELY_AVAILABLE, SKR_SELECT_CLUSTER, SKR_MIN_HVT_VALUE, SKR_MIN_HVT_PERCENTAGE } from './components/skr-agent-events.js'

const ENDPOINT_ISKR               = "/iskr/"
const ENDPOINT_RPA_EVENT          = "/rpa/event/" 
const ENDPOINT_RPA_CLUSTER        = "/rpa/cluster/" 
const ENDPOINT_RPA_SEARCH         = "/rpa/search/" 
const ENDPOINT_RPA_CONVERSATION   = "/rpa/conversation/" 
const ENDPOINT_RPA_CONVERSATIONS  = "/rpa/conversations/" 
const ENDPOINT_RPA_COMPLETION     = "/rpa/completion-stream/" 

// test_clusters = [
// Garmin      '0000287212',
// Quarq Dzero '0037899287',
// Shiman Di2  '0066134261']
// this.cluster_id = "0066134261"
// this.agent_id = "skr-web-agent-001"

class App extends React.Component {
  constructor(props) {
    super(props);

    // Get SKR_Client intialized for RPA-API calls
    this.client = new SKR_Client();
    console.debug(">>>>>>>>>>>>> APP CONSTRUCTOR: RPA_HOST ", this.client.host_active)
    
    const urlParams = new URLSearchParams(window.location.search); // restore for production, remove 2 lines above
    const encoded_iskr = urlParams.get('iskr');
    this.iskr = false;
    this.agent_id = "skr-web-agent-001";
    this.cluster_id = null;
    this.referrer_id = null;

    this.check_if_iskr()
    console.debug(">>>>>>> EXTRACT:", this.iskr, this.agent_id, this.cluster_id)

    this.state = {
      screenMode: 'dark',
      currentTitle: "seekr ai",
      isSidebarOpen: false,
      isFullScreen: false,
      isClusterLoaded: (this.iskr) ? false : true, // Is the cluser loaded and redady to render.
      isLoading: (this.iskr) ? true : false,  // Is the cluster being fetched from the API
      isNew: (this.iskr) ? false : true,
      is_iskr: (this.iskr) ? true : false,
      isLoadConversation: false,
      isSearchOptionsFound: false,
      isSearchTargetFound: false,
      isSearchError: false,
      isLandingPage: true,
      conv_id: null,
      cluster: null,
      messages: null,
      isHostActive: this.client.host_active,
    }


    // this.cluster = null     // CONFIRM if we want this ONLY in state . Cluster object
    this.side_barRef = React.createRef();

    this.setDarkMode()
  }

  check_if_iskr() {
    // Checks the inbound URL to see if it contains an iSKR.
    // If so set agent_id and cluster_id
    const currentUrl = window.location.href;
    this.isfetching_iskr = false;  // Flag to prevent multiple calls to get_iskr

    if (currentUrl.includes("iskr")) {
      const url_parts = currentUrl.split('iskr');
      const ids = url_parts[1].split('/');
      this.iskr = true;
      this.referrer_id = ids[1];
      this.cluster_id = ids[2];
      this.agent_id = "skr-web-agent-001";
      console.debug(">>>>>>>>>>>>> iSKR: ", this.iskr, this.referrer_id, this.cluster_id, this.agent_id)
    }
  }

  get_screenMode() {
    const preferredColorScheme = localStorage.getItem('preferredColorScheme');
    if (preferredColorScheme) {
      this.setState({ screenMode: preferredColorScheme });
    } else {
      this.setState({ screenMode: 'dark' }); // Default to light color scheme
    }
  }
  
  async componentDidMount() {
    // Confirm the host is available after ping
    // await this.client.ping_host();
    this.client.ping_host(this.onResponseHandler);
  }

  componentDidUpdate() {
    // Scroll to the bottom when the input value changes
    const { screenMode } = this.state;
    localStorage.setItem('preferredColorScheme', screenMode);
    document.documentElement.setAttribute('data-theme', screenMode);
    // this.setDarkMode();
    // window.matchMedia('(prefers-color-scheme: dark)').addListener(this.setDarkMode);

  }

  // TBD - should handle offer clicked event from the product viewer
  onOfferClicked = (offer) => {console.debug('offer clicked')}
  
  // TBD - should handle events from the product viewer
  onAgentEvent = (event) => {
    const end_point = `${ENDPOINT_RPA_EVENT}${this.agent_id}`
    console.debug('agent event', event)
    this.client.rpa_post(end_point, "RPA_AGENT_EVENT", this.onResponseHandler, event);
}

  // Handler to nofy messanger that new cluster is viewed to set the context
  onClusterViewed = (cluster) => {
    // console.debug("############### CLUSTER VIEWED ##############: ", cluster)
    this.setState({cluster: cluster}) 
    // this.cluster = cluster

}

  // Render a new SKR to search for a product
  on_new = () => {this.setState({isSearchTargetFound: false, isSearchOptionsFound: false, isLandingPage: false, isNew: true, isSearchError: false})}

  // Call back from search to render the cluster
  // Handles search found target event, render swiper with Cluster
  onSearchFound = (response) => {
    if (response.status === 200) {
        console.debug("onSearchFound: ", response)
        this.search_context = response.data.search_context
        this.search_results = response.data.search_results
        const cluster       = response.data.search_results[0].cluster
        this.offer_context  = cluster.offer_context
        this.setState({isSearchTargetFound: response.data.search_target_hit, isSearchError: response.data.search_error, cluster: cluster, messages: response.data.messages, conv_id:response.data.conv_id, isClusterLoaded: true, isLandingPage: false, isNew: false, is_iSKR: false}) 
    }
}

  // Call back from search to render the search options
  // Handles Search returned multiple options, render swiper to select one
  onSearchOptions = (response) => {
    if (response.status === 200) {
        console.debug("onSearchOptions: ", response)
        this.search_context = response.data.search_context
        this.search_results = response.data.search_results
        this.setState({conv_id:response.data.conv_id, messages: response.data.messages, isClusterLoaded: false, isLandingPage: false, isNew: false, is_iSKR: false, isSearchError: response.data.search_error, isSearchOptionsFound: response.data.search_results}) 
    }
}

// Handles Client.get_cluster responses if iSKR was in the URL
  onResponseHandler = (response, msg_id) => {

    console.debug(">>>>>>>>>>>>> onResponseHandler: ", msg_id, response )
    if (msg_id === 'RPA_PING') {
      console.debug(">>>>>>>>>>>>> RPA_PING RESPONSE: ", response)
      if (response.status === 200) {
      this.setState({isHostActive: true})
      }
      else {
        this.setState({isHostActive: false})
      }
    }

    else if (response.status === 200 && msg_id !== 'RPA_PING') {
      // Handle MSG IDs

      if (msg_id === 'RPA_GET_ISKR') {
        const cluster        = response.data.cluster
        this.offer_context  = response.data.cluster.offer_context
        this.setState({conv_id:response.data.conv_id,  messages: response.data.messages, currentTitle: response.data.title, cluster: cluster, isClusterLoaded: true, isSearchTargetFound: true, isLoading: false, is_iskr: false}) 
      }

      if (msg_id === 'RPA_GET_CLUSTER') {
        console.debug(">>>>>>>>>>>>> RPA_GET_CLUSTER RESPONSE: ", response)
        this.offer_context  = response.data.cluster.offer_context
        this.setState({ cluster: response.data.cluster, isClusterLoaded: true, isSearchTargetFound: true, isLoading: false, is_iskr: false}) 
      }

      if (msg_id === 'RPA_AGENT_EVENT') {
        console.debug(">>>>>>>>>>>>> RPA_AGENT_EVENT RESPONSE: ", response)
        if (response.data.event.event_id === SKR_SELECT_CLUSTER) {
          this.setState({ messages: response.data.response.messages, isClusterLoaded: true, isSearchTargetFound: true, isClusterLoaded: true, isSearchOptionsFound: false, isLoading: false, is_iskr: false}) 
        }
      }
      if (msg_id === 'RPA_GET_CONVERSATION') {
        console.debug(">>>>>>>>>>>>> RPA_GET_CONVERSATION RESPONSE: ", response)
        this.setState({conv_id:response.data.conv_id,  messages: response.data.messages, currentTitle: response.data.title}) 
      }
      
    }
    else if (response.data.status === 501) {
      this.setState({isClusterLoaded: false, isLoading: false, isNew: true}) 
    }
  };

  // Handles Search returned multiple options, render swiper to select one
  onGetCluster = (cluster_id) => {
      console.error("********* ONGET-CLUSTER CALLED BY SOMEONE ****************: ", cluster_id)
      // if (cluster_id ) {
      //   this.client = new SKR_Client();
      //   this.client.rpg_set_response_handler(this.onResponseHandler);
      //   this.client.rpg_get_cluster({cluster_id: cluster_id});
      // }
    }

  // Handles Search returned multiple options, render swiper to select one
  onClusterSelected = (cluster_id) => {
    // console.debug("********* onClusterSelected ****************: ", cluster_id, this.search_results)
    if (cluster_id && this.search_results) {
      const sr = this.search_results.find(result => result.cluster_id === cluster_id);
      this.setState({cluster: sr.cluster, isSearchTargetFound: true, isClusterLoaded: true, isLandingPage: false, isNew: false, is_iSKR: false}) 
      this.onAgentEvent({agent_id: this.agent_id, event_id: SKR_SELECT_CLUSTER, params: {conv_id: this.state.conv_id, cluster_id: cluster_id}})
    }
    else if (cluster_id ) {
      console.debug("********* TODO: GET CLUSTER FROM RPA ****************: ", cluster_id)
    }
  }

  onConversationClick = (conversation) => {
    // Handles Sidebar conversation click to load conversation

    // Change the state to get the new cluster and conversation id
    console.debug("********* onConversationClick ****************: ", conversation)
    this.cluster_id = conversation.cluster_id
    this.setState({ isClusterLoaded: false, isLoading: true, conv_id: conversation.conv_id})
    this.get_cluster()
    this.get_conversation(conversation)
    // Close the sidbar
    this.toggleSidebar()
  }

  get_iskr() {
    // Call the iskr API to get a Cluster if one is in the params.
    // console.debug("####### GET CLUSTER: ", this.cluster_id, !this.isClusterLoaded, !this.isLoading )
    if (this.cluster_id && !this.isClusterLoaded && !this.isLoading) {
      const end_point = `${ENDPOINT_ISKR}${this.agent_id}/${this.referrer_id}/${this.cluster_id}`
      console.debug(">>>>>>> GET_ISKR >>>>>>>>>", end_point);
      this.isFetching = true
      this.client.rpa_get(end_point, "RPA_GET_ISKR", this.onResponseHandler)

    }
  }

  get_cluster() {
    // Call the API to get a Cluster if one is in the params.
    console.debug("####### RPA-GET CLUSTER: ", this.cluster_id, !this.isClusterLoaded, !this.isLoading )
    if (this.cluster_id && !this.isClusterLoaded && !this.isLoading) {
      const end_point = `${ENDPOINT_RPA_CLUSTER}${this.agent_id}/${this.cluster_id}`
      console.debug(">>>>>>> GET_CLUSTER >>>>>>>>>", end_point);
      this.isFetching = true
      this.client.rpa_get(end_point, "RPA_GET_CLUSTER", this.onResponseHandler)

    }
  }

  get_conversation(conversation) {
    // Call the API to get a Cluster if one is in the params.
    const end_point = `${ENDPOINT_RPA_CONVERSATION}${this.agent_id}/${conversation.conv_id}`
    console.debug("####### RPA GET CONVERSATION: ", end_point);
    this.isFetching = true
    this.client.rpa_get(end_point, "RPA_GET_CONVERSATION", this.onResponseHandler)

  }

  toggleFullScreen = () => {
    this.setState(prevState => ({
      isFullScreen: !prevState.isFullScreen
    }));
    // console.debug('toggled full screen ...')
  }

  toggleSidebar = () => {
    const { isSidebarOpen } = this.state;
    this.setState({isSidebarOpen: !isSidebarOpen})
  }


  togglescreenMode = () => {
    const { screenMode } = this.state;
    this.setState({ screenMode: screenMode === 'light' ? 'dark' : 'light' });
  };

  setDarkMode = () => {
    const prefersDarkMode = window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches;
    if (prefersDarkMode) {
      document.documentElement.setAttribute('data-theme', 'dark');
    }
  };
  
  setConvTitle(title) { this.setState({currentTitle: title}) };

  render_offers() {
    console.debug("********* RENDERING CLUSTER ************", this.state.cluster)
    if (this.state.isClusterLoaded) {
      return (
        <div className="skr-swiper-container">
            <SwipeViewer 
              isCluster={true}
              response={this.state.cluster} 
              pageURL={this.state.cluster.currentURL} 
              referenceProduct={this.state.cluster.reference_product}
              onOfferClicked={this.onOfferClicked}
              onAgentEvent={this.onAgentEvent}
              screenMode={this.state.screenMode}
              side_barRef={this.side_barRef}
              toggleFullScreen={this.toggleFullScreen}/>
        </div>
      ); 
    }
  } 

  render_search_options() {
    console.debug("********* RENDERING SEARCH OPTIONS ************", this.search_results)
    if (this.state.isSearchOptionsFound) {
      // hack to remove any empty clusters
      this.search_results = this.search_results.filter((result) => result.cluster.offer_context.isClusterEmpty !== true)

      return (
        <div className="skr-swiper-container">
            <SwipeViewer 
              isSearch={true}
              search_results={this.search_results} 
              onGetCluster={this.onClusterSelected}
              onAgentEvent={this.onAgentEvent}
              onSetCluster={this.onClusterViewed}
              screenMode={this.state.screenMode}
              side_barRef={this.side_barRef}
              toggleFullScreen={this.toggleFullScreen}/>
        </div>
      ); 
    }
  } 

  render_messaging() {
    console.debug(">>>>>>>>>>>> APP: RENDER MESSAGING (state): ", this.state)
    if (this.state.isFullScreen) {return null} // Don't render messaging if in full screen viewing a product
    const offer_context = (this.state.isClusterLoaded) ? this.offer_context : false
    // const search_context = (this.state.isSearchOptionsFound || this.state.isSearchTargetFound) ? this.response.offer_context : false
    if (this.state.isClusterLoaded || this.state.isSearchOptionsFound) {
      return ( <Messaging 
        isSearch={this.state.isSearchOptionsFound}
        isCluster={this.state.isSearchTargetFound}
        isIskr= {this.state.is_iskr}
        search_context = {this.search_context} 
        search_results = {this.search_results} 
        cluster  =  {this.state.cluster} 
        offer_context = {this.offer_context} 
        messages =  {this.state.messages}
        client={this.client}
        conv_id = {this.state.conv_id}/>)}
    return null
  }

  render_sidebar() {
    if (this.state.isSidebarOpen) {
      console.debug("APP: RENDERING Sidebar : ", this.state.isSidebarOpen)
      return (
      <section className="left-wrapper container" ref={this.side_barRef}>  
        <SideBar 
          isSidebarOpen= {this.state.isSidebarOpen} 
          toggleSidebar = {this.toggleSidebar} 
          on_new={this.on_new} 
          onConversationClick={this.onConversationClick}
          client={this.client}
          />  
      </section> )
    }
  }

  render_topbar() {
    if (!this.state.isSidebarOpen && !this.state.isFullScreen) {
    return (
      <section> 
        <div className="skr-top-bar-container">
          <button className="skr-top-bar-hamburger" type="button" onClick={this.toggleSidebar}>
            <img src={skr_hamburger} title="show menu" alt="show sidebar menu" />
          </button>          
          <button className="skr-top-bar-hamburger" type="button" onClick={this.on_new}>
            <img src={skr_marker} title="new seekr" alt="new seekr" />
          </button>          
        </div>
      </section>
    );
    }
  }

  render_navs() {
    if (!this.state.isSidebarOpen && !this.state.isFullScreen) {return (<div>{this.render_topbar()}</div>)}
    if (this.state.isSidebarOpen) {return (<div>{this.render_sidebar()}</div>)}
  }
  // These are only remdered for Clusters, not search pages
  render_meta_tags() {
    // console.debug("render_meta_tags: ", this.state, this.response)
    const cluster = this.state.cluster

    const title = "Seekr: "+cluster.cluster_meta.brand + " " + cluster.cluster_meta.name;
    const url = `${this.client.host_ip}/iskr/${this.agent_id}/${this.cluster_id}`
    const img_url = (cluster.cluster_meta.imgs && cluster.cluster_meta.imgs.length > 0) ? cluster.cluster_meta.imgs[0] : '' ;

    return (
      <>
        <Helmet>
            <title>{title}</title>
            <meta charSet="utf-8" />
            <meta name="description" content={cluster.description} />
            <meta property="og:type" content="product" />
            <meta property="og:title" content={title} />
            <meta property="og:description" content={cluster.description} />
            <meta property="og:image" content={img_url} />
            <meta property="og:url" content={url} />
            <link rel="canonical" href={url} />
        </Helmet>
      </>
    )
  }


  render_loading() {
    // console.debug("Rendering SKR-Loader")

    return (
      <div className="app-container" >  

        <section className="main-container"> 
          <div></div>
          <div>  <img className="skr-logo-hero" src={skr_marker_lg} />  </div>
          <div></div>
        </section>
      </div>)
  }

  // Renders the Search Results Pages
  render_search_results() {
    console.debug(">>>>>>>>>>>> APP: RENDER SEARCH RESULTS (state): ", this.search_results, this.state)

    if (this.state.isLoading) { return (<div> {this.render_skr_loading()} </div>) }
    
    // Set the context for the conversation
    if (this.state.isSearchOptionsFound ) {
      return (
        <div className="app-container" >  
          {/* <div>{this.render_meta_tags()}</div> */}
          <div>{this.render_sidebar()}</div>

          <section className="main-container"> 
            <div>{this.render_topbar()}</div>
            <div className="offers-container"> {this.render_search_options()} </div>
            <div className="messaging-container"> {this.render_messaging()} </div>
          </section>

        </div>
      )
    } else {return (null) }
  } 

  // Renders the Search Target SKR (Cluster)
  render_cluster() {
    console.debug(">>>>>>>>>>>> APP: RENDER CLUSTER (state): ", this.state)

    if (this.state.isLoading) { return (<div> {this.render_skr_loading()} </div>) }

    // Set the context for the conversation
    if (this.state.isClusterLoaded ) {
      return (
        <div className="app-container" >  
          <div>{this.render_meta_tags()}</div>
          <div>{this.render_sidebar()}</div>

          <section className="main-container"> 
            <div>{this.render_topbar()}</div>
            <div className="offers-container"> {this.render_offers()} </div>
            <div className="messaging-container"> {this.render_messaging()} </div>
          </section>

        </div>
      )
    } else {return (null) }
  } 

  render_search() {
    console.debug("render_search : ", this.state, this.client.host_active)

    return (
      <div className="app-container" >  
        <section className="main-container"> 
          <div className="new-skr-container">  
            <Search 
              onSearchFound={this.onSearchFound}
              onSearchOptions={this.onSearchOptions}
              isLandingPage={this.state.isLandingPage}
              isSearchError={this.state.isSearchError}
              client={this.client}
          /> </div>
        </section>
      </div>
    );
  }
  
  render_footer() {
    return (
          <div className="info"> Seekr is currently in limited availability and restricted to cycling products </div>
    );
  }


    

  render() {
    console.debug("Rendering State: ", this.state)
    
    if (this.state.isLoading)                   { return (<div> {this.render_loading()} </div>) }
    else if (this.state.isNew)                  { return (<div> {this.render_search()} </div>) }
    else if (this.state.isSearchError )         { return (this.render_search()) }
    else if (this.state.isSearchTargetFound)    { return (<div> {this.render_cluster() } </div> ) }
    else if (this.state.isSearchOptionsFound )  { return (this.render_search_results()) }
    else { return (<div>Houston, we have a problem ...</div>) }
  }
}


export default App;

