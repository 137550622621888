import React from 'react';
import { Toast, Row, Col, ProgressBar } from 'react-bootstrap';


// Import Swiper styles
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Navigation, Parallax } from 'swiper'
import 'swiper/swiper-bundle.css';

// Import SKR Components
import '../skrApp.css';
import skr_logo from "../assets/branding/skr-logo-mark-dark-mode.png"
import skr_mark from "../assets/branding/skr-marker@2x.png"
import no_ads from "../assets/No_Ads_dark.png"
// import SKR_Client from './skr-client.js';
import SKR_player from './skr-player.js';
import SRP_Viewer from './skr-srp-viewer.js';

const ENDPOINT_RPA_SEARCH = "/rpa/search/" 
const VERSION_LABEL = "limited release - cycling only: rc-1.0.0"
const COPYRIGHT_LABEL = "© seekr.ai 2024 | "
const FOOTER_LABEL = COPYRIGHT_LABEL + VERSION_LABEL;

//  Shimano XTR Di2 Rear Derailleur (Medium Cage)
//  Garmin Edge 1030
//  Quarq DZero Power Meter Spider for Specialized

const DEFAULT_AGENT_ID = "skr-web-agent-001"

class Search extends React.Component {
    constructor(props) {
      super(props);

      // Set the callback handlers for searching
      this.onSearchFound = this.props.onSearchFound;
      this.onSearchOptions = this.props.onSearchOptions;

      this.agent_id = (this.props.agent_id) ? this.props.agent_id : DEFAULT_AGENT_ID

      // Get SKR_Client intialized for messaging
      this.client = this.props.client

      // Set the state
      this.state = {
        screenMode: 'dark',
        textareaHeight: '24px',
        isPlayerOn: false,
        search_input: "",
        isSearchTargetFound: false,
        isSearchOptionsFound: false,
        isLandingPage: this.props.isLandingPage,
        isNewSearch: !this.props.isLandingPage,
        host_active: this.client.host_active,
        isSearchError : this.props.isSearchError,
        progress: 0,
        intervalId: null,
      }

      // references are for resizing the textarea and scrolling messages to bottom
      this.textareaRef = React.createRef();    
      this.swiperContainerRef = React.createRef();  // reference for swiper container to change size
      this.maxSwiperWidth = '0px'  // default value

    }
    
    componentDidMount() {
      // Scroll to the bottom when the component mounts or when the input value changes
      // this.scrollToBottom();
      if (this.swiperContainerRef.current) {
        this.set_container_width()
      }  
    };
  
    componentDidUpdate(prevProps, prevState) {
      // Scroll to the bottom when the input value changes.
    //   removed since it cause change when typing and user may want to scroll back to see previous messages
    //   this.scrollToBottom();
    // If the intervalId was not set in the previous state, start the progress bar
    if (!prevState.intervalId && !this.client.host_active) {
      this.startProgressBar();
    }

    if (this.client.host_active && this.state.intervalId) { 
      this.setState({ progress: 0, intervalId: null })
    }

    }

    set_container_width(){
      // This is a hack to fix bug in Swiper that explodes out the container sizes at slide level.
      const swiperWrapper = this.swiperContainerRef.current.querySelector('.swiper-wrapper');
      const swiper_gap = 50
  
      if (this.swiperContainerRef.current) {
        const styles = window.getComputedStyle(swiperWrapper);
        const paddingLeft = parseFloat(styles.paddingLeft);
        const paddingRight = parseFloat(styles.paddingRight);
  
        const sww = this.swiperContainerRef.current.offsetWidth - paddingLeft - paddingRight - swiper_gap
        const sbw = (this.props.side_barRef.current != null)? this.props.side_barRef.current.offsetWidth : 0
        const width = (sww - sbw)+'px'
        
        swiperWrapper.style.maxWidth = width; // Set your desired max-width dynamically
        this.maxSwiperWidth = width  //save for later use in sub-swipers
  
        return 
      }
    }
    
    // cleanInput(inputString) {
    //   return inputString
    //     .trim() // Remove white spaces from the start and end
    //     .toLowerCase() // Convert to lowercase
    //     .replace(/[\r\n]+/g, ' ') // Replace newline characters with a space
    //     .replace(/[^\w\s]/gi, ''); // Remove other special characters
    // }

    cleanInput(inputString) {
      return encodeURIComponent(
        inputString
          .trim() // Remove white spaces from the start and end
          .toLowerCase() // Convert to lowercase
          .replace(/[\r\n]+/g, ' ') // Replace newline characters with a space
          .replace(/[^\w\s]/gi, '') // Remove other special characters
      );
    }
    
    get_search = async () => {
      // Gets a newconversation from the RPA API for this agent and cluster_id
      // @app.get("/iskr/{agent_id}/{cluster_id}")
      const query = this.cleanInput(this.state.search_input)
      const end_point = `${ENDPOINT_RPA_SEARCH}${this.agent_id}/${query}`
      console.debug(">>>>>>> GET SEARCH >>>>>>>>>", end_point);
      this.isFetching = true
      this.client.rpa_get(end_point, "NEW_SEARCH", this.onResponseHandler)
    }

    // SEARCH HANDLER
    
    onResponseHandler = (response, msg_id) => {
      // Handles the server responses from RPG requests.  
      // Based on the Search results, determines which action to take. 
      // For rendering the results, it calls the callback functions passed in from the parent component.
      // depending on the response, it will set the state to render the appropriate view.
      if (response.status === 200) {
        this.response = response
        console.debug(">>>>>>> HANDLE RESPONSE >>>>>>>>>", msg_id, response.data);
       

        // Direct Hit - callback to main app to handle Target Cluster
        if (response.data.search_target_hit) { 
          console.debug(">>>>>>> SEACH TARGET HIT >>>>>>>>>", response.data);
          this.setState({isSearchTargetFound: true, isLandingPage: false, search_input: ""})
          this.onSearchFound(response) 
          return}

        // Search Results, callback to main app to handle Search Options
        else if (response.data.search_result_set) { 
          console.debug(">>>>>>> POSSIBLE TARGETS FOUND >>>>>>>>>", response.data);
          this.setState({isSearchOptionsFound: true, isLandingPage: false, isNewSearch: false, search_input: ""})
          this.onSearchOptions(response) 
        }
        // No results returned.  Try again.
        else if (response.data.search_error ) { 
          console.debug(">>>>>>> ERROR: SEACH NOT FOUND >>>>>>>>>", response.data.search_results);
          this.setState({isSearchError: true, isSearchTargetFound: false, isSearchOptionsFound: false, isLandingPage: false, search_input: ""})
          this.onSearchOptions(response) 
        }

      } else {console.debug("RESPONSE ERROR", response.status)}

      };
  
      render_landing_page() {
        return (
          <div className="new-skr-container"> 
            <section>
              {/* <div> {this.render_header()} </div> */}
            </section>
  
            <section>
              <div className="skr-new">
                  <div> {this.render_header()} </div>
                  <div className="row align-middle pt-3">
                          <div className="copy-wrapper d-flex flex-column col-12">
                          <h1> Gets the product you want.  Instantly.</h1>
                          <h1> </h1>
                          <h1> </h1>
                        </div>
                  </div>
                  <div className="row align-middle pt-3">
                    <div className="copy-wrapper d-flex flex-column col-xs-12 col-gap">
                        <div style={{ display: 'flex', alignItems: 'center'}}>
                            <img src={no_ads} alt="No Ads" height="90" style={{ paddingRight: '15px' }}/>
                            <div>
                                <h2 className="headline" >No more wasted time searching.</h2>
                                <h3 className="sub-headline">No ads,   no clickbait,   no 'sponsored' products.</h3>
                            </div>
                        </div>
                    </div>
                  <div className="copy-wrapper d-flex flex-column col-xs-12 col-gap">
                         <h2 className="headline">Get the best deal.</h2>
                         <h3 className="sub-headline">AI tracks every product, every price - at every merchant.</h3>
                       </div>

                </div>                  
                <div className="row align-middle pt-3">
                          <div className="copy-wrapper d-flex flex-column col-12">
                            {this.render_search_box()}
                            <h3> Don't search. Send a seekr.</h3>
                        </div>
                  </div>
              </div>
            </section>
            <section>
              <h5> {FOOTER_LABEL} </h5>
            </section>
  
          </div>
        );
      }
  

    // render_landing_page() {
    //   return (
    //     <div className="new-skr-container"> 
    //       <section>
    //         {/* <div> {this.render_header()} </div> */}
    //       </section>

    //       <section>
    //         <div className="skr-new">
    //             <div> {this.render_header()} </div>
    //             <div className="row align-middle pt-3">
    //                     <div className="copy-wrapper d-flex flex-column col-12">
    //                     <h1> Gets the product you want.  Instantly.</h1>
    //                     <h1> </h1>
    //                     <h1> </h1>
    //                   </div>
    //             </div>
    //             <div className="row align-middle pt-3">
    //             <div className="copy-wrapper d-flex flex-column col-xs-12 col-gap">
    //                 <div style={{ display: 'flex', alignItems: 'center'}}>
    //                   <img src={no_ads} alt="No Ads" height="75" style={{ paddingRight: '10px' }}/>
    //                   <h2 className="headline" >No more wasted time searching.</h2>
    //                 </div>                        
    //                 <h3 className="sub-headline">No ads,  no clickbait,  no 'sponsored' products.</h3>
    //                   </div>
    //                   <div className="copy-wrapper d-flex flex-column col-xs-12 col-gap">
    //                     <h2 className="headline">Find the right product.</h2>
    //                     <h3 className="sub-headline">Knows every product, feature, spec, alternative.</h3>
    //                   </div>
    //                   <div className="copy-wrapper d-flex flex-column col-xs-12 col-gap">
    //                     <h2 className="headline">Get the best deal.</h2>
    //                     <h3 className="sub-headline">Tracks every price - at every merchant.</h3>
    //                   </div>
    //             </div>
    //             <div className="row align-middle pt-3">
    //                     <div className="copy-wrapper d-flex flex-column col-12">
    //                       {this.render_search_box()}
    //                       <h3> Don't search. Send a seekr.</h3>
    //                   </div>
    //             </div>
    //         </div>
    //       </section>
    //       <section>
    //         <h5> {FOOTER_LABEL} </h5>
    //       </section>

    //     </div>
    //   );
    // }

    startProgressBar = () => {
      console.log('Starting progress bar');
      const intervalId = setInterval(() => {
        this.setState(prevState => {
          if (prevState.progress >= 100) {
            clearInterval(this.state.intervalId);
            return { progress: 100 };
          } else {
            return { progress: prevState.progress + 100/135 };
          }
        });
      }, 2000);
  
      this.setState({ intervalId });
    };
  
    render_service_resuming() {
      const min = 0;
      const max = 100;
      return (
          <div className="progress-bar">
            Hold Tight - Service is Resuming!
            <ProgressBar now={this.state.progress} min={min} max={max} label={`${Math.round(this.state.progress)}%`} />
          </div>
      );          
    }

    render_search_error() {
      if (!this.state.isSearchError) { return (<div></div>)}
      return (
        // <div className="copy-wrapper d-flex flex-column col-12">
          <Toast onClose={() => this.setState({isSearchError: false})} show={this.state.isSearchError} delay={3000} autohide className="skr-search-error" >
            <Toast.Body >
                NO PRODUCTS MATCHED QUERY!
            </Toast.Body>
          </Toast>
      );
    }

    render_search() {
      return (
        <div className="new-skr-container"> 

          <section>
          </section>
          <section>
            <div> {this.render_header()} </div>
            <div className="skr-new">
                <div className="row align-middle pt-3">
                    <div className="copy-wrapper d-flex flex-column col-12">
                        <h1> Get the product you want.  Instantly.</h1>
                          {this.render_search_box()}
                          {this.render_search_error()}
                        <h3> Don't search. Send a seekr.</h3>
                    </div>
                </div>
              </div>
          </section>
          <section>
            <h5> {FOOTER_LABEL} </h5>
          </section>
        </div>
      );
    }

    render_search_options() {
      const search_results = this.response.data.search_results

      return (
        <div className="swiper-container" ref={this.swiperContainerRef}>
          <Swiper
              slidesPerView={1}
              speed={600}
              parallax={true}
              spaceBetween={50}
              pagination={{ clickable: true }}
              modules={[Parallax, Pagination]}
              onSwiper={(swiper) => this.swiper = swiper}
          >
              {search_results.map((srp, index) => {
                  return (
                      <SwiperSlide key={index}> 
                          <SRP_Viewer 
                              response={srp} 
                              item={srp} 
                              meta={srp.cluster_meta} 
                              // onAgentEvent={this.props.onAgentEvent} 
                              // onOfferClicked={this.props.onOfferClicked} 
                              maxSwiperWidth={this.state.maxSwiperWidth}
                          />
                      </SwiperSlide>
                  );
              })}
          </Swiper>
      </div>
        );
    } 
  
    // Renders a video player.  Leave this for product review videos.
    render_player() {
      return (
        <div className="new-skr-container">  <SKR_player /> </div>
      )
    }

    // User Input Handlers
    handleInput = (event) => {
      if (event.nativeEvent.inputType === 'insertLineBreak') { this.get_search() }
      else {this.setState({search_input:event.target.value}) }
    };
    
    render_search_box() {
      // render the message input box
      const placeholder = "What can I get you?" 
      const style = (this.props.isNew) ? { height: this.state.textareaHeight, width: '300px', marginLeft: '10px' } : { height: this.state.textareaHeight }
      
      // Checks if service is offline and renders a message
      if (!this.client.host_active) { 
        return ( this.render_service_resuming() )
      }

      return (
      <div className="search-container">
          <textarea
            type="text"
            ref={this.textareaRef}
            placeholder={placeholder}
            value={this.state.search_input}
            style={style}
            onChange={(e) => this.handleInput(e)}
          />
          <button className="search-button" onClick={this.get_search}>Send it</button>
      </div>
      )
    }

    render_header() {
      if (this.state.isPlayerOn ) {
        return ( <img className="skr-player-logo-overlay" src={skr_mark} /> ); 
      } else {
        return ( <img className="skr-logo-header" src={skr_logo} /> ); 
      }
    }

    render() {
      if (this.state.isLandingPage)               { return (this.render_landing_page()) }
      else if (this.state.isNewSearch)            { return (this.render_search()) }
      else if (this.state.isSearchOptionsFound )  { return (this.render_search_options()) }
    }
    
  
} export default Search;
  