export function price_gauge(offer) {

  const discount = offer.discount
  const price_box_data = offer.price_box

  // Price Box Parameters
  const blank = ['', '']
  const msrp_price = price_box_data.high.price_range[1]
  const median_price = price_box_data.median.price
  const median_discount = Math.round((msrp_price - median_price) / msrp_price * 100)
  const high_sellers =  (price_box_data.high.sellers > 1)? "sellers" : "seller"
  const median_sellers =  (price_box_data.median.sellers > 1)? `sellers` : `seller `
  const best_sellers =  (price_box_data.best.sellers > 1)? `sellers` : `seller`
  const high = [`msrp $${price_box_data.high.price_range[1]}`, `(${price_box_data.high.sellers} ${high_sellers})`]
  const best = [`best $${price_box_data.best.price}`, `(${price_box_data.best.sellers} ${best_sellers})`]
  const msrp_only = [`msrp  $${price_box_data.median.price}`, `(${price_box_data.median.sellers} ${median_sellers})`]
  const median = [`typical  $${price_box_data.median.price}`, `(${price_box_data.median.sellers} ${median_sellers})`]

  // Gauge Parameters
  // console.debug("DISCOUNT: ", discount, "Price-Box:", price_box_data)
  const baseColor = "#343541";
  const borderColor = "#979797";
  const yellowColor = "#F79F1D";
  const redColor = "#EC021C";
  const maxDiscount = 50;
  const maxDeal = (discount >= maxDiscount) ? true: false ;
  const minimumDeal = 10;
  const yellowDeal = 15;
  const discountPoint = (discount / maxDiscount) *100;
  const yellowThreshold = 30;
  const yellowGradientStart = (10 / maxDiscount) * 100;
  const yellowGradientEnd = (offer.isDiscounted)? median_discount: 0;
  const redGradientStart = yellowGradientEnd +15;
  const redGradientEnd = 30;
  const discountPercentage = Math.min(discount, maxDiscount) / maxDiscount;
  const baseBlur = discountPoint + 4
  
  // // Price Box Data Set
  var price_box= []
  var gradient = '';
  var pop_median_only = false;
    
  console.debug("******* Price Box Data: *******", discount, median_discount)
  if (discount === 0) {
    if (median_discount === 0) {
      gradient = `linear-gradient(to bottom,  ${baseColor} 0%, ${baseColor} 50%)`;
      price_box.push(msrp_only)}
  }
  else if (discount <= 10) {
    if (median_discount === 0) {
      gradient = `linear-gradient(to bottom,  ${baseColor} 0%, ${yellowColor} ${minimumDeal}%, ${yellowColor} ${discountPoint}%, ${baseColor} ${baseBlur}%)`;
      price_box.push(msrp_only, best)}
    else {
      gradient = `linear-gradient(to bottom,  ${baseColor} 0%, ${yellowColor} ${minimumDeal}%, ${yellowColor} ${discountPoint}%, ${baseColor} ${baseBlur}%)`;
      price_box.push(high, best)}
  }
  else if (discount <= 15) {
    if (median_discount === 0) {
      gradient = `linear-gradient(to bottom,  ${baseColor} 0%, ${baseColor} ${minimumDeal-5}%, ${yellowColor} ${minimumDeal}%, ${yellowColor} ${discountPoint}%, ${baseColor} ${baseBlur}%)`;
      price_box.push(msrp_only, blank, best)}
    else {
      gradient = `linear-gradient(to bottom,  ${baseColor} 0%, ${baseColor} ${minimumDeal-5}%, ${yellowColor} ${minimumDeal}%, ${yellowColor} ${discountPoint}%, ${baseColor} ${baseBlur}%)`;
      price_box.push(high, median, best, blank, blank)}
  }
  else if (discount <= 20) {
    if (median_discount === 0) {
      gradient = `linear-gradient(to bottom,  ${baseColor} 0%, ${baseColor} ${minimumDeal-5}%, ${yellowColor} ${minimumDeal}%, ${yellowColor} ${discountPoint}%, ${baseColor} ${baseBlur}%)`;
      price_box.push(msrp_only, blank, best)}
    else {
      gradient = `linear-gradient(to bottom,  ${baseColor} 0%, ${baseColor} ${minimumDeal-5}%, ${yellowColor} ${minimumDeal}%, ${yellowColor} ${discountPoint}%, ${baseColor} ${baseBlur}%)`;
      price_box.push(high, median, best, blank, blank)}
  }
  else if (discount < 25) {
    if (median_discount === 0) {
      gradient = `linear-gradient(to bottom,  ${baseColor} 0%, ${yellowColor} ${yellowGradientStart}%, ${redColor} ${discountPoint}%, ${baseColor} ${baseBlur}%)`;
      price_box.push(msrp_only, blank, best, blank, blank)}
    else {
      gradient = `linear-gradient(to bottom,  ${baseColor} 0%, ${yellowColor} ${yellowGradientStart}%, ${yellowColor} ${discountPoint}%, ${baseColor} ${baseBlur}%)`;
      price_box.push(high, median, best, blank, blank)}
  }
  else if (discount <= 30) {
    if (median_discount === 0) {
      gradient = `linear-gradient(to bottom,  ${baseColor} 0%, ${yellowColor} ${yellowGradientStart}%, ${redColor} ${discountPoint}%, ${baseColor} ${baseBlur}%)`;
      price_box.push(msrp_only, blank, best, blank, blank)}
    else {
      gradient = `linear-gradient(to bottom,  ${baseColor} 0%, ${yellowColor} ${yellowGradientStart}%, ${yellowColor} ${yellowGradientEnd}%, ${redColor} ${redGradientStart}% ${discountPoint}%, ${baseColor} ${baseBlur}%)`;
      price_box.push(high, median, best, blank, blank)}
  }
  else if (discount <= 40) {
    if (median_discount === 0) {
      price_box.push(msrp_only,  median, best, blank)
      gradient = `linear-gradient(to bottom,  ${baseColor} 10%, ${redColor} ${15}% ${discountPoint}%, ${baseColor} ${baseBlur}%)`;
    }
    else {
      price_box.push(high,  median, best, blank )
      gradient = `linear-gradient(to bottom,  ${baseColor} 0%, ${yellowColor} ${yellowGradientStart}%, ${yellowColor} ${yellowGradientEnd}%, ${redColor} ${redGradientStart}% ${discountPoint}%, ${baseColor} ${baseBlur}%)`;
    }
  }
  else if (discount <= 50) {
    if (median_discount === 0) {
      price_box.push(msrp_only,  blank, blank, blank, best)
      gradient = `linear-gradient(to bottom,  ${baseColor} 10%, ${redColor} ${15}% ${discountPoint}%, ${baseColor} ${baseBlur}%)`;
    }
    else {
      price_box.push(high,  blank, median, blank, best)
      gradient = `linear-gradient(to bottom,  ${baseColor} 0%, ${yellowColor} ${yellowGradientStart}%, ${yellowColor} ${yellowGradientEnd}%, ${redColor} ${redGradientStart}% ${discountPoint}%, ${baseColor} ${baseBlur}%)`;
    }
  }
  else  if (maxDeal){
    if (median_discount === 0) {price_box.push(msrp_only, blank, median, blank, best)}
    else {price_box.push(high,blank, median, blank, best)}
    gradient = `linear-gradient(to bottom,  ${baseColor} 0%, ${yellowColor} ${yellowGradientStart}%, ${yellowColor} ${yellowGradientEnd+10}%, ${redColor} ${redGradientStart+30}% ${discountPoint}%, ${baseColor} ${baseBlur}%)`;

  }  

  // Remove the median element if it is the same as the best price
  if (offer.isDiscounted && price_box_data.best.price === price_box_data.median.price ) { 
    // Replace the median element with a blank since it is the same as the best price
    const medianIndex = price_box.findIndex(box => box === median);
    if (pop_median_only) {price_box.splice(medianIndex, 1) }
    else { price_box.splice(medianIndex, 1, blank) }
  }

  return {gradient, price_box};
}

