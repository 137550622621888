import React from 'react';
import axios from 'axios';


const TIMEOUT_RPA_POST     = 5000
const TIMEOUT_RPA_GET      = 15000
const RPA_ENDPOINT_PING    = "/rpa/ping"
const SKR_HOST             = "https://seekr-ai.com"
const ENDPOINT_RPA_RESUME  = "https://us-east1-a9-dev.cloudfunctions.net/service-man/resume" 


// NOTE: Client-host Discovery protocol has been removed and replaced with a DNS and proper SSL certificate
// I've left the code here for reference, but it is not used in the current version of the app

class SKR_Client extends React.Component {
  constructor() {
    super();
    this.state = {
    }

    console.debug("... loading client ")
    // RPA host is set by the get_host() method after app intialization (called by skr-app and held here for subcomponents)
    this.host_ip  = SKR_HOST ; 

    // Agent Configs / State
    this.agent_id     =  "skr-web-agent-001"

    // API Configs
    // references are for resizing the textarea and scrolling messages to bottom
    this.messages = []; 
    
    this.host_active = true;  // RPA host active is set by the ping_host() method after intialization
    this._retries = 0;   // Max retries to get the RPG host, set to 3 by get_host
    this.max_retries = false;   // Max retries to get the RPG host, set to 3 by get_host        
  }

  rpa_get = async (end_point, msg_id, responseHandler, _params=null) => {
      // Sends a request to the RPA API.  The response is handled by the rpa_response_handler
      
      if (end_point === null) { return("CLIENT: ERROR: api endpoint is required") }  
      console.debug(">>>>>>>>>>>>>>> GET >>>>>>>>>>>>>>>>>>>>>", end_point, msg_id, _params)

      const api_endpoint = `${this.host_ip}${end_point}`
      const params = (_params !== null) ? JSON.stringify(_params) : null
      const headers = { 
        'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept',
        'Access-Control-Allow-Origin': '*' 
      }

      axios.defaults.timeout = TIMEOUT_RPA_GET
      console.debug("CLIENT: GET(api_endpoint, params, headers):   ", api_endpoint, params, headers)
      axios.get(api_endpoint, {params, headers})
        .then( (response) => { 
          console.debug("GET RESPONSE:", msg_id, response, )
          responseHandler(response, msg_id) })  
        .catch(function (error) {
          if (error.response) {
              // The request was made and the server responded with a status code outside of the range of 2xx
              console.debug('Server Error:', error.response.data);
          } else if (error.request) {
              // The request was made but no response was received
              console.debug('No Response:', error.request);
          } else {
              // Something happened in setting up the request and triggered an Error
              console.debug('Axios Configuration Error:', error.message);
          }
      })
    }

  rpa_post = async (end_point, msg_id, responseHandler, params=null) => {
    // Sends a request to the RPA API.  The response is handled by the rpa_response_handler

    console.debug(">>>>>>>>>>>>>>> POST - NOT IMPLEMENTED >>>>>>>>>>>>>>>>>>>>>", end_point, msg_id, params)
    // console.debug(">>>>>>>>>>>>>>> POST - NOT IMPLEMENTED >>>>>>>>>>>>>>>>>>>>>", end_point, msg_id, responseHandler, params)
    if (end_point === null) { return("CLIENT: ERROR: api endpoint is required") }
    return
    const api_endpoint = `${this.host_ip}${end_point}`
    const headers = { 'Content-Type': 'application/json' }
    // const params = (_params !== null) ? JSON.stringify(_params) : null


    console.debug("CLIENT: POST: api_endpoint, params, headers   ", api_endpoint, params, headers)
    axios.defaults.timeout = TIMEOUT_RPA_POST
    axios.post(api_endpoint, params, { headers })
      .then( (response) => { 
        console.debug("CLIENT: rpa_response: ", response)
        responseHandler(response, msg_id) })  
      .catch(function (error) {
        if (error.response) {
            // The request was made and the server responded with a status code outside of the range of 2xx
            console.debug('Server Error:', error.response.data);
        } else if (error.request) {
            // The request was made but no response was received
            console.debug('No Response:', error.request);
        } else {
            // Something happened in setting up the request and triggered an Error
            console.debug('Axios Configuration Error:', error.message);
        }
    })
  }  

  // Host Discovery Services to get the RPA Host and determine if it is available
  // Needs to be blocking call to set the IP and port before the app starts
  // If the host is not available, try to resume it
  // This could take up to 3min, so we need to wait for it to be available
  async resume_host(responseHandler=null) {
    if (this.host_active) {
      // If host IP and port are already in the state, no need to call the discovery service
      return;
    }

    console.debug("********** RESUMING: RPA_HOST: ...", ENDPOINT_RPA_RESUME)
    // set axios headers to allow CORS for this object from GCS
    const headers = {'Content-Type': 'application/x-www-form-urlencoded'}  
    const response = await axios.get(ENDPOINT_RPA_RESUME, { headers, timeout: 180000 });

    // The JSON content is in response.data
    console.debug("********** RESUME RESPONSE: ", response.data)
    // if response 200 , set host active
    if (response.status === 200) {
      this.host_active = true
      if (responseHandler !== null) { responseHandler(response, "RPA_PING") }
      console.debug(">>>>>>>>>>> RPA_HOST AVAILABLE >>>>>>>>>>");
      return this.host_ip
    }
    else {
      console.error('>>>>>>>>>>>>>> RPA_HOST IS NOT AVAILABLE <<<<<<<<<<<<<<');
      this.host_active = false
      if (responseHandler !== null) { 
        const errorResponse = { status: 503, message: "No host available" };
        responseHandler(errorResponse, "RPA_PING") }

      return null
    }
  }

  /* PING the RPG Service to confirm if it is available.
  if available, set the agent host */
  async ping_host(responseHandler=null) {
    // ping_host = async (retry=0) => {
    const API = this.host_ip+RPA_ENDPOINT_PING
    const headers = {'Content-Type': 'application/x-www-form-urlencoded'}  
    console.debug(">>>>>> ping rpa host ...", API)
    axios.get(API, { headers: headers, timeout: 500 })
        .then( (response) => { 
          this.host_active = true
          if (responseHandler !== null) { responseHandler(response, "RPA_PING") }
          console.debug(">>>>>> rpa host is active ...", this.host_active)
        })
        .catch(async (error) => {
          console.debug(">>>>>> rpa is not available ...", error)
          this.host_active = false
          // Something happened in setting up the request that triggered an Error
          if (responseHandler !== null) { 
            const errorResponse = { status: 503, message: "No host available" };
            responseHandler(errorResponse, "RPA_PING") }
          await this.resume_host(responseHandler)
        });
  }

  render() {
    console.log("loading client")
    this.ping_host();  // Ping the host to see if it is available
    return (null);
  }
} 
export default SKR_Client;
  
