import React, { useState } from 'react';
import { Toast, Row, Col, Card, Button } from 'react-bootstrap';

import Overlay from 'react-bootstrap/Overlay';
import Tooltip from 'react-bootstrap/Tooltip';

import { ArrowRightCircle, XCircleFill, XCircle, BoxArrowUp, BagPlus, BagPlusFill,  Bullseye } from 'react-bootstrap-icons'

import {SKR_WIDELY_AVAILABLE, SKR_MIN_HVT_VALUE, SKR_MIN_HVT_PERCENTAGE, SKR_ACT_TRACK, ISKR_ISSUED,
  SKR_OFFER_VIEW, 
  SKR_HVT_VIEW, 
  SKR_OFFER_ATB, 
  SKR_OFFER_CLICK     ,
  SKR_OFFER_PURCHASED ,
  SKR_ERROR           ,
  ISKR_ATTACHED       ,
  ISKR_CONVERSION     ,
  DMS_PRODUCT_VIEW    ,
  DMS_ATC_CLICK       ,
  DMS_CHECKOUT_CLICK  } from './skr-agent-events.js'

// Import Swiper styles
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Navigation, Parallax } from 'swiper'
import 'swiper/swiper-bundle.css';
import '../assets/styles/bootstrap.min.css';
import '../assets/styles/skr_comp_styles.css';
import flatSwipe from '../assets/icons/Flat-Swipe.gif'
import skr_logo from "../assets/branding/skr-logo-description.png"
import skr_mark from "../assets/branding/skr-marker@3x.png"
import {price_gauge} from './skr-price_gauge.js'

class Offer_Summary extends React.Component {

  /**
   * constructor
   *
   * @object  @props  parent props
   * @object  @state  component state
   */
  constructor(props) {

      super(props);

      this.offer_context = this.props.response.offer_context
      this.item = this.props.item

      // console.debug("Offer_Summary constructor", this.props)
      this.state = {
        referenceProduct: this.props.referenceProduct,
        toggleParent: this.props.toggleView,
        getView: this.props.viewState,
        isDetailView: false,
        isTracked: false,
        isInBag: false,
        isNotification: false,
        msgNotification: "",
        isHVT : this.offer_context.isHVT
      }
      // this.swiperContainerRef = React.createRef();  // reference for swiper container to change size
      this.offer = {}
      this.set_offer_features()

  }

  setMerchantName() {
    const item = this.props.item
    const name = (item.merchant) ? item.merchant_name : item.url_kv.pld

    if (item.merchant){ return (name) }
    else {
      var m = name
      m = m.replace(".", ' ')
      m = m.replace("com", '')
      m = m.replace("www", '')
      m = m.replace("WWW", '')
      m = m.replace(".", '')
      return(m)  
    }
  }

  get_offer_status() {
    const oc = this.offer
    // console.debug("get_offer_status: ", oc)
    if (!oc.isDiscounted) { 
      return ("List Price at all Merchants") }
    if (oc.isHVT && oc.isLimitedAvailable) {
      return ( `Limited Offer at ${oc.availability_count} of ${oc.total_available} merchants`) }
    
      if (oc.isHVT && oc.isWidelyAvailable) {
        return ( `Offer is widely available at ${oc.availability_count} merchants`) }
    if (oc.isHVT ) {
      return ( `Offer available at ${oc.availability_count} of ${oc.total_available} merchants`) }

    return ( `Offer available at ${oc.availability_count} merchants`)
  }

  get_share_msg() {
    var msg = ""
    if (this.offer.isLimited) {
      msg = `My Seekr found this limited offer for you - Now ${this.offer.discount_percent} off. Save $${this.offer.discount_value}.`
    }
    else if (this.offer.isHVT) {
      msg = `My Seekr found this deal for you - Now ${this.offer.discount_percent} off. Save $${this.offer.discount_value}.`}
    else if (this.offer.isWidelyAvailable) {
      msg = "Seekr shared this offer with you."}
    // console.debug("get_share_msg: ", msg)
    return msg
  }

  set_offer_features() {
    const product = this.props.item
    const oc = this.offer_context
    const meta = this.props.meta
    
    // Set Product Features
    this.product_brand = (meta.brand === null) ? product.brand: meta.brand
    this.product_name = (meta.name === null) ? product.name :  meta.name
    this.product_brand_name = this.product_brand + " "+this.product_name
    this.product_description = (meta.description === null) ? product.description : meta.description
    this.product_imgs = (meta.imgs != null && meta.imgs.length != 0) ? meta.imgs: product.imgs

    // Set Product Price / Savings
    this.product_price = (product.availability === "InStock")? "$"+product.price: "$"+product.price+" - Last Price" 
    this.product_oldPrice = oc.isDiscounted ? "$"+(oc.msrp.toFixed(2)) : ""

    // Set Product Logistics
    this.product_isInStock = (product.availability === "OutOfStock") ? "Out of Stock": product.availability 
    this.skuAttributes = (product.sku) ? " | "+product.sku : ""  
    this.merchant_name = this.setMerchantName()

    // Set Offer Features
    console.debug("******** FROM OFFER ********: ", oc)
    this.offer.isHVT = oc.isHVT
    this.offer.isDiscounted = oc.isDiscounted
    this.offer.discount = (oc.isDiscounted) ? (oc.discount_percentage) : 0
    this.offer.discount_value = oc.discount_value
    this.offer.discount_percent = (oc.isDiscounted) ? (oc.discount_percentage).toString()+"%" : ""
    this.offer.savings_msg = (oc.isDiscounted) ? "Save $"+(oc.discount_value.toString()) : ""
    this.offer.rank = (oc.offer_rank) ? oc.offer_rank+1 : ""
    this.offer.availability_count = oc.availability_count
    this.offer.total_available = oc.total_available
    this.offer.at_merchants = oc.availability_count.toString()
    this.offer.isLimitedAvailable = oc.isLimitedAvailable
    this.offer.isWidelyAvailable = oc.isWidelyAvailable
    this.offer.status = oc.offer_messaging.status_msg
    this.offer.summary = this.offer.status
    this.offer.price_box = oc.price_box
    this.offer.share_msg = this.get_share_msg()

    console.debug("******** SET OFFER ********: ", this.offer)
    return
  }

  render_actions() {  
    return (
      <div className="row align-middle skr-prod-actions-row">
          <div className="col-3 d-flex"> 
            {this.render_action_bag()}
          </div>
          <div className="col-3 d-flex"> 
            {this.render_action_track()}
          </div>
          <div className="col-3 d-flex"> 
            {this.render_action_share()}
          </div>
          <div className="col-3 d-flex " >
            {this.render_action_cto()}
          </div>
      </div>
    )
  }

  render_action_bag() {   
    if (this.state.isInBag) {
      return (<BagPlusFill className="skr-offer-action" onClick={this.onToggleBag} color="#FC2504" > </BagPlusFill>)  
    } else {
      return (<BagPlus title="Keep Seekr" className="skr-offer-action"  onClick={this.onToggleBag}> </BagPlus>)  
    }
  }

  render_action_track() {  
    if (this.state.isTracked) {
      return ( <Bullseye className="skr-offer-action" onClick={this.onToggleTracker} color="#FC2504" > </Bullseye> )  
    } else {
      return ( <Bullseye title="Add Tracker" className="skr-offer-action" onClick={this.onToggleTracker}> </Bullseye> )  
    }
  }

  render_action_share() { 
    return ( <BoxArrowUp title="Share" className="skr-offer-action" onClick={this.onShare}> </BoxArrowUp>)  
  }

  render_action_cto() {
    return (
      <div className="skr-site-link col-md-3 col-sm-6 col-xs-6 d-flex" >
        <a href={this.props.item.url} target="_blank" rel="noopener noreferrer">
          <span className="skr-actions-highlight">
              <ArrowRightCircle  title="To Merchants Site" onClick={() => this.props.onOfferClicked(this.props.item.url, this.props.item.ranking.savings_percentage, this.props.item.ranking.savings_value, this.state.isHVT)} />
          </span>
        </a>
      </div>
    );
  }
  renderPagination() {   
    if (this.state.isDetailView) { return (<></>) }
    else {
      return (
        <>
          <img className="img-responsive skr-swipe-indicator float-start" src={flatSwipe} /> 
        </>
      )
    }
  }
// Action/Event Handlers
  // Sharing is not working on Chrome.
  // TODO - NEED TO TEST WITH WORKING PAGES TO GET OG TAGS RENDERING IN MSG

  onShare = () => {
    const share_url = `https://127.0.0.1:5000/RPG-CLUSTER?cluster_id=${this.cluster_id}&agent_id=${this.agent_id}`
    
    const data = {title: this.product_brand_name, url: share_url, text: this.offer.share_msg}
    // console.debug("onShare: ", data)

    if (navigator.share) {
      navigator.share(data)
        .then(() => {
          this.props.onAgentEvent({event_id: ISKR_ISSUED, cluster_id: this.props.item.cluster_id, isHVT: this.state.isHVT})
        })
        .catch((error) => {
          console.error('Error sharing:', error);
        });
      } else {
        console.debug('Web Share API not supported');
    }
  };

  // toggles View 
  onToggleView= () => { 
    console.debug("****** onToggleView ******")
    this.setState(_state => ({
      isDetailView: !_state.isDetailView,
    }));
    this.state.toggleParent()
  }

  // toggles Watch 
  onToggleTracker= () => { 
    const isSet = !this.state.isTracked
    this.setState(_state => ({
      isTracked: !_state.isTracked,
      isNotification: true,
      msgNotification: (!_state.isTracked) ? 'Added Product Tracker' : 'Removed Product Tracker'
    }));
    this.props.onAgentEvent({event_id: SKR_ACT_TRACK, setTo: isSet, cluster_id: this.props.item.cluster_id, isHVT: this.state.isHVT})
  }

  // toggles Bag 
  onToggleBag= () => { 
    const toggle = !this.state.isInBag
    this.setState(_state => ({
      isInBag: toggle,
      isNotification: true,
      msgNotification: (toggle) ? 'Added to your bag' : 'Removed from your bag'
    }));
  }

  // toggles Notifications 
  onToggleNotification= () => {
    this.setState(_state => ({
      isNotification: false,
      msgNotification: ''
    }));
  }
  
  render_notification() {
    if (!this.state.isNotification) {
      return(<></>)
    } else {  
      console.debug(">>>>>> RENDERING NOTIFICATION <<<<<<<", this.state.msgNotification)
      return (
        <div className="skr-notification-container d-flex justify-content-center align-items-center w-100" >
          <Row className="col-md-12 ">
            <Col xs={7}>
              <Toast onClose={() => this.onToggleNotification()} show={this.state.isNotification} delay={3000} autohide className="skr-notification">
                <Toast.Body> {this.state.msgNotification} </Toast.Body>
              </Toast>
            </Col>
          </Row>
        </div>
      );
    }
  }
  
  render_price_gauge() {
    // Gauge Parameters
    const gaugeHeight = 250;
    const gaugeWidth = 45;
    const baseColor = "#343541";
    const borderColor = "#979797";
    const {gradient, price_box} = price_gauge(this.offer)

    return (
      <div className="skr-histogram">
        <div className="skr-histogram-img-container"
              style={{
                height: `${gaugeHeight}px`,
                width: `${gaugeWidth}px`,
                backgroundColor: baseColor,
                border: `1px solid ${baseColor}`,
                background: gradient,
              }} >
        </div>
        <ul className="skr-histogram-overlay">
            {price_box.map(([pricePoint, numSellers]) => (
              <div key={pricePoint} className="skr-histogram-price-point">
                <span className="skr-histogram-price">{pricePoint}</span> 
                <span className="skr-histogram-sellers">{numSellers} </span>
                </div>
            ))}
        </ul>
      </div>
    );
  }


  render_product_details() {  

    return (
      <div>
        {/* <div className="skr-prod-name">{this.product_name}</div> */}
        <div className="skr-summary-description" >{this.product_description}</div>
      </div>
    )
  }

  render_product_pricing() {   
    return (
    <div className="skr-prod-pricing col">
      <span>  {this.product_price}  </span>
      <span className="skr-prod-price-old">  {this.product_oldPrice}</span>
    </div>
    )
  }     

  render_product_img() {  

    if((this.state.isDetailView) | (this.product_imgs == null)){
      return(<></>)
    } else if (!this.offer.isDiscounted) {
      return (
        <div className="skr-img-inset">  
          <img className="skr-summary-img-inset" src={this.product_imgs[0]}  onClick={this.onToggleView} />
        </div>
      )
    } else {
      return (
        <div className="skr-img-inset">  
          <img className="skr-summary-img-inset" src={this.product_imgs[0]}  onClick={this.onToggleView} />
            <span className="overlay skr-prod-tag-overlay">  {this.offer.discount_percent}  </span>
        </div>

      )
    }
  }


  render_offer_header() {
    return ( <h1 className={"skr-summary-title col"}> {this.product_brand_name} </h1> )
  }

  render_offer_bottom_line() {
    const className = this.offer.isHVT ?  "skr-summary-bottom-line-savings-urgent" : "skr-summary-bottom-savings-line";
    return ( <h2 className={className}> {this.offer.savings_msg} </h2>  )
  }
  
  render_header() {
    return ( <img className="skr-logo-header" src={skr_logo} /> ); }

  render_summary() {
    return (
      <div className="swiper-slide">
        <div className="skr-card">

          {/* ROW 1 - OFFER CLASSIFICATION */}
          <div className="row">
            <div className="col ">
                <div className="skr-card-title col"> {this.render_offer_header()} </div> 
              </div>
          </div>


          {/* ROW-3 PRODUCT DETAILS */}
          <div className="row">

              {/* col-3 */}
              <div className="col-md-4 col-sm-6 col-xs-12"> 
                <div> {this.render_product_img()} </div> 
                <div>{this.render_offer_bottom_line()}</div>
              </div>

              {/* col-2 */}
              <div className="col-md-4 col-sm-6 col-xs-12"> 
                <div> {this.render_product_details()} </div> 
                <div> {this.render_product_pricing()} </div> 
              </div>

              {/*  col-1 */}
              <div className="col-md-4 col-sm-6 col-xs-12">
                <div>{this.render_price_gauge()}</div>
              </div>
          </div>

          {/* ROW-2 OFFER STATUS - MERCHANT */}
          <div className="row skr-card-title">
            <div>
              <h2 className={"skr-summary-bottom-line col"}>
                <span> {this.offer.summary}</span>
              </h2>
            </div> 
          </div>

          {/* ROW-4 PRODUCT ACTIONS */}
          <div className="row skr-actions">
              {/*  col-1 */}
              <div className="col-md-4 col-sm-6 col-xs-12 col-gap">
                <div style={{ width: '100%', height: '100%' }} > 
                {this.render_notification()} </div>
              </div>

              <div className="col-md-8 col-sm-6 col-xs-12">
                <div> {this.render_actions()} </div>
              </div>
          </div >
        </div>
      </div>
    );
  }


  render_product_image_swiper() {
    console.debug("******* render_SUMMARY_image_swiper *********", this.props.item)
    const product = this.props.item;

    if ((!this.state.isDetailView) | (product.imgs == null)) {
      return(<></>)
    } else {
        return (
          <div> 
              <button >
                  <XCircle className="skr-product-img-close" type="button" style={{ color: 'red' }} onClick={this.onToggleView}/>
              </button>

              <div className="swiper-container" ref={this.swiperContainerRef}>
                  <Swiper
                    className='skr-prod-detail-swiper'
                    style={{
                      "--swiper-navigation-color": "#555",
                      "--swiper-pagination-color": "#FC2504",
                    }}
                    speed={600}
                    parallax={true}
                    // pagination={{
                    //   clickable: true,
                    // }}
                    modules={[Parallax, Pagination, Navigation]}
                    nested={true}
                    slidesPerView={1}
                  >
                    {product.imgs.map(src => (
                        <SwiperSlide key={product.nodeId+src}> 
                          <div>
                            <img className="skr-prod-detail-img" src={src} /> 
                          </div>
                        </SwiperSlide>
                      ))}
                  </Swiper>
              </div>
          </div>
        )
      }  
  }

  render() {
    if (this.state.isDetailView) {
      return ( <div> {this.render_product_image_swiper()} </div> )
    }
    else{
      return ( <div> {this.render_summary()}  </div>)
    }
  }
}

export default Offer_Summary;


