import React from 'react';
import { animateScroll } from 'react-scroll';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm'
import '../skrApp.css';
// import skr_logo from "../assets/branding/skr-logo-mark-dark-mode.png"
// import SKR_Client from './skr-client.js';
import { HandThumbsDown, HandThumbsDownFill,  HandThumbsUp, HandThumbsUpFill,  ChatLeftText, ChatLeftTextFill, XCircleFill, XCircle, BoxArrowUp, BagPlus, BagPlusFill,  Bullseye } from 'react-bootstrap-icons'


// CSS Imports
import 'swiper/swiper-bundle.css';
import '../assets/styles/bootstrap.min.css';
import '../assets/styles/skr_comp_styles.css';

const ENDPOINT_ISKR               = "/iskr/"
const ENDPOINT_RPA_EVENT          = "/rpa/event/" 
const ENDPOINT_RPA_CLUSTER        = "/rpa/cluster/" 
const ENDPOINT_RPA_SEARCH         = "/rpa/search/" 
const ENDPOINT_RPA_CONVERSATION   = "/rpa/conversation/" 
const ENDPOINT_RPA_CONVERSATIONS  = "/rpa/conversations/" 
const ENDPOINT_RPA_COMPLETION     = "/rpa/completion-stream/" 

const AGENT_ID_DEFAULT = "skr-web-agent-001"
// const CONV_ID = "61aa5bb7-0518-4db5-990b-f77e852e6b46"  FOR DEV TEST ONLY
// const CLUSTER_ID = "0066134261"

class Messaging extends React.Component {
    constructor(props) {
      super(props);

      console.debug("MESSENGER PROPS: ", props)
      // Init the state for the messaging component from the context
      this.offer_context = (this.props.isCluster) ? this.props.offer_context : null
      this.search_context = (this.props.isSearch) ? this.props.search_context : null
      this.agent_id = (this.props.agent_id) ? this.props.agent_id : AGENT_ID_DEFAULT

      // Get SKR_Client intialized for messaging
      this.client = this.props.client
      this.host_ip  = this.client.host_ip

      console.debug(">>>>>>> HOST PARAMS SET >>>>>>>>>", this.host_ip, this.agent_id)

      // this.cluster_id = (this.props.isCluster) ? this.props.offer_context.cluster_id : null
      this.cluster_id = (this.props.isCluster) ? this.props.cluster.cluster_id : null
      console.debug("MESSENGER INIT: ", this.cluster_id, this.offer_context, this.search_context, this.agent_id)
      
      this.state = {
        messages: (this.props.messages) ? this.props.messages : [],
        isCluster: (this.props.isCluster) ? this.props.isCluster : false,
        isSearch: (this.props.isSearch) ? this.props.isSearch : false,
        isIskr: (this.props.isIskr) ? this.props.isIskr : false,
        cluster: (this.props.cluster) ? this.props.cluster : null,
        isConversing : (this.props.conv_id) ? true : false,                // Used for rendering feed and input
        conv_id: (this.props.conv_id) ? this.props.conv_id : null,
        screenMode: (this.props.screenMode) ? this.props.screenMode : 'dark',
        message_input: '',
        isTyping: false,
        message_response: {},
        currentTitle: "seekr",
        textareaHeight: '20px',
        response_msg: "",
        receive_state: false,  
        isThumbsUp: false,
        isThumbsDown: false,
        isCommented: false,
      }

    this.isSearchMsgSet = false // Hack to stop from rendering twice onComponentDidMount
    this.isInitializing = false
    this.isFetching = false

    // references are for resizing the textarea and scrolling messages to bottom
    this.feedRef = React.createRef();
    this.textareaRef = React.createRef();
    this.messageRefs = [];

  }
  componentDidMount() {
    // Scroll to the bottom when the component mounts or when the input value changes
    // console.debug(">>>>>>>>>> COMPONENT MOUNTED | STATE :",this.state)
    if      (this.isFetching)     { return}   // wait for API response, must be set true in caller 
    else if (this.state.isSearch) { return }  // Conversation is ready to render
    else if (this.state.isCluster){ return }  // Conversation is ready to render
    else if (this.state.conv_id)  { this.get_conversation(AGENT_ID_DEFAULT, this.state.conv_id) }
    else { console.debug(">>>>>>>>>> COMPONENT MOUNTED | NO CONV STATE SET <<<<<<<<<<<<") } 
    };
    
  componentDidUpdate(prevProps) {
      // Scroll to the bottom when the input value changes.
      // console.debug(">>>>>>>>>> MSG COMPONENT UPDATED :",this.props, this.state)

      if (this.state.isConversing) {this.scrollToBottom()}
      // Handle message changes from the parent component
      if (prevProps.messages === null || prevProps.messages !== this.props.messages) { 
        // console.debug(">>>>>>>>>> COMPONENT MESSAGES UPDATED :",this.props.messages, " <<<<<<<<<<<<")
        // this.setState({ messages: this.props.messages })
        // this.scrollLastMessageToTop();
        this.setState({ messages: this.props.messages }, () => {
          // Callback function ensures that scroll happens after state is updated
          this.scrollLastMessageToTop();
        });
        }

      // Handles updates to the cluster that is being viewed, and updates the state
      // This will also need to update the conversation context if the cluster changes
      if (this.props.isCluster && (prevProps.cluster === null || prevProps.cluster.cluster_id !== this.props.cluster.cluster_id)) { 
        // console.debug(">>>>>>>>>> COMPONENT UPDATED | cluster :",this.props.cluster, " <<<<<<<<<<<<")
        this.setState({cluster: this.props.cluster}) 
        this.offer_context = this.props.cluster.offer_context}
    }
    
    get_conversation = async (agent_id=null, conv_id=null) => {
      // Gets the conversation from the RPA API
      // @app.get("/conversation/{agent_id}/{conv_id}")

      const end_point = `{ENDPOINT_RPA_CONVERSATION}${this.agent_id}/${this.state.conv_id}`
      console.debug(">>>>>>> GET CONVERSATION >>>>>>>>>", end_point);
      this.isFetching = true
      this.client.rpa_get(end_point, "GET_CONVERSATION", this.handleResponse)
    }

    set_conversation(response) {
      // Set the conversation response from the RPA API
      console.debug(">>>>>>> SET CONVERSATION >>>>>>>>>", response);
      // this.state.conv_id = response.data.conv_id
      this.setState({messages: response.data.messages, conv_id: response.data.conv_id})
    }

    // STREAMING RESPONSE HANDLER
    get_completion = async () => {
      // console.debug(">>>>>>> GET COMPLETION >>>>>>>>>",  this.state.message_input);
      const user_message = {"role":"user", "name": "KFM", "content": this.state.message_input}
      this.addMessage(user_message);
      this.setInputSending()

      const evtSource = new EventSource(`${this.host_ip}${ENDPOINT_RPA_COMPLETION}${this.agent_id}/${this.state.conv_id}?msg=${user_message.content}`);
      
      evtSource.addEventListener("pending_message",     this.handlePendingMessage);
      evtSource.addEventListener("completion_pending",  this.handleCompletionPending);
      evtSource.addEventListener("completion_chunk",    this.handleCompletionChunk);
      evtSource.addEventListener("completion_response", this.handleCompletionResponse);
  
      this.eventSource = evtSource;
      console.debug(">>>>>>> STREAM CREATED >>>>>>>>>", this.eventSource);
    }

    removeEventListener() {
      const evtSource = this.eventSource;
      evtSource.close();
      if (evtSource) {
        evtSource.removeEventListener("pending_message",    this.handlePendingMessage);
        evtSource.removeEventListener("completion_pending", this.handleCompletionPending);
        evtSource.removeEventListener("completion_chunk",   this.handleCompletionChunk);
        evtSource.removeEventListener("completion_response",this.handleCompletionResponse);
      }
      console.debug("******* STREAM: CLOSED ", this.state.messages)
    }
  
    handlePendingMessage = (event) => {
      // Handles receiving pending msg If response is pending, and there is a pending message to render
      const agent_message = {"role":"assistant", "name": "seekr", "content": event.data}
      this.addMessage(agent_message);
      console.debug("######### STREAM PENDING MSG #########", this.agent_message);
      this.setState( {receive_state: "pending"} );
      };
  
    handleCompletionPending = (event) => {
      // Notifies that a response is pending
      console.debug(">>>>>>> STREAM OPENED >>>>>>>>>", event);
      // let newMessage = this.state.messages[this.state.messages.length - 1];
      let newMessage = this.agent_message;
      newMessage.content = "";
      this.setState((prevState) => ({
          messages: [...prevState.messages.slice(0, -1), newMessage],
          receive_state: "pending",
        }));
  
    };

    handleCompletionChunk = (event) => {
      // Handles receiving chunks of the response, adds to end of current response

      // Add the new chuunk to the last message in the list
      let newMessage = this.agent_message
      newMessage.content = newMessage.content + event.data;
      this.agent_message = newMessage;
      this.setState((prevState) => ({
          messages: [...prevState.messages.slice(0, -1), newMessage],
          receive_state: "receiving",
        }));

    };
  
    handleCompletionResponse = (event) => {
      console.debug(" ******** STREAM COMPLETE ********* ", event)  
      this.setState((prevState) => ({
          receive_state: "complete",
        }));
  
      this.removeEventListener();
      this.handleClearInput();

    };
    
    // STREAMING RESPONSE HANDLER //  END

    handleResponse = (response, msg_id) => {
      // Handles the inboud message and sets the message container 
      // for next conversation turn
      console.debug(">>>>>>> HANDLE RESPONSE >>>>>>>>>", response, msg_id);
      this.isFetching = false
      if (response.status !== 200) { console.debug("CLIENT: ERROR: ", response); return }
      else if (msg_id === "GET_CONVERSATION")   { this.set_conversation(response) }
      else if (msg_id === "NEW_CLUSTER")        { this.set_conversation(response) }
      else if (msg_id === "NEW_SEARCH")         { this.set_conversation(response) }

      };
    
    addMessage(newMessage) {
      // Adds a new message to the message stack.  
      console.debug(">>>>>>> ADD MESSAGE >>>>>>>>>", newMessage)
      // Adds a new message to the message container
      this.setState((prevState) => ({
        messages: [...prevState.messages, newMessage]
      }));
      // if agent message, keep a reference to it
      if (newMessage.role === "assistant") { this.agent_message = newMessage }
      else { this.agent_message = null }
    };

    // User Input Controller and dHandlers
    adjustTextareaHeight = () => {
      const { current: textarea } = this.textareaRef;
      const isTextWrapped = textarea.scrollHeight > textarea.offsetHeight;
      if (isTextWrapped) { textarea.style.height = `60px`}
    };    

    handleInput = (event) => {
      if (event.nativeEvent.inputType === 'insertLineBreak') { this.get_completion() }
      else {this.setState({message_input:event.target.value, isTyping: true}) }
    };

    handleClearInput() { 
        console.debug(">>>>>>> HANDLE CLEAR INPUT >>>>>>>>>")
        const { current: textarea } = this.textareaRef;
        textarea.style.height = `20px`
        this.setState({message_input: '', isTyping: false})
    };
  
    setInputSending() { this.setState({message_input: '. . .', isConversing: true}) };
   
    scrollLastMessageToTop = () => {
      // Scrolls the last message to the top of the feed
      // This is not working as expected, TODO: someone needs to debug

      if (this.messageRefs.length) {
        const lastMessageRef = this.messageRefs[this.messageRefs.length - 1];

        setTimeout(() => {
          if (this.feedRef.current && lastMessageRef) {
            this.feedRef.current.scrollTop = lastMessageRef.offsetTop;
          }
        }, 200); // 200ms delay      
      }
    }

    // scrollLastMessageToTop = () => {
    //   if (this.messageRefs.length) {
    //     const lastMessageRef = this.messageRefs[this.messageRefs.length - 1];
    //     if (this.feedRef.current && lastMessageRef) {
    //       // Use animateScroll to smoothly scroll to the specific position
    //       animateScroll.scrollTo(lastMessageRef.offsetTop, {
    //         containerId: 'msgfeed',
    //         smooth: true
    //       });
    //     }
    //   }
    // }    

    scrollToBottom = () => {
      // automatically scrolls to the bottom of the message feed after each turn
      if (this.feedRef.current) {
          animateScroll.scrollToBottom({
          containerId: 'msgfeed', // Set the ID of the scrollable container
          smooth: true,
          });
      }  
      };

    render_msg_input() {
      // render the message input box
      // console.debug("render_msg_input", this.props.isNew, this.state.isConversing)
      const placeholder = (this.state.isConversing)? "Send a message" : (this.props.isNew) ? "What can I get for you?" : "How can I help you?"
      const style = (this.props.isNew) ? { height: this.state.textareaHeight, width: '600px', marginLeft: '10px' } : { height: this.state.textareaHeight }

      return (
      <div className="input-container">
          <textarea
            type="text"
            ref={this.textareaRef}
            placeholder={placeholder}
            value={this.state.message_input}
            style={style}
            onChange={(e) => this.handleInput(e)}
            onScroll={(e) => this.adjustTextareaHeight(e)}

          />
          <button className={`input-container-button ${this.state.isTyping ? 'active' : ''}`} onClick={this.get_completion}>Send it</button>
      </div>
      )
    }

    render_feed() {
      // Renders the message feed
      // console.debug(">>>>>>>>>> RENDER FEED <<<<<<<<<<<<", this.state.messages)
      if (!this.state.messages) {return (<div></div>)}
      return (
        <ul className="feed" id="msgfeed" ref={this.feedRef}>
          {this.state.messages.map((message, index) => {
            if (message.role === "system") {
              return null; // Skip rendering for messages with "system" role
            } else {
              return (
                <li key={index} ref={el => this.messageRefs[index] = el}>
                  <div className="message-wrapper">
                    <p className="role">{message.name} </p>
                    <div className="feed-message">{this.render_message_content(message)}</div>
                  </div>
                </li>
              );
            }
          })}
        </ul>
      );
    }

    render_message_content(message) {
      // Renders the message content based on the message type  
      // Renders either a text message or markdown that could a listor tables
      // const isMarkdown = message.content.includes("**")
      const isMarkdown = true
      if (isMarkdown) {return ( 
        <ReactMarkdown 
          children={message.content} 
          remarkPlugins={[[remarkGfm, {singleTilde: false}]]}  
          className='markdown-content'>
            
          </ReactMarkdown> )
      } else {return message.content}
    }          

  
    render_footer() {
       return ( <div className="info"> Seekr is currently in limited availability and restricted to cycling products </div> ); 
      }

    render_messaging() {

      return (
          <div className="messaging-container"> 
              {this.render_feed()}
  
              <div className="input-container container-fluid"> {this.render_msg_input()} </div>
              {/* <div className="info"> Seekr is currently in limited availbility and restricted to cycling products </div> */}
          </div>
      );
      }  

    set_search_context() {
      // Renders initial search message
      console.debug(">>>>>>>>>> SET SEARCH CONTEXT <<<<<<<<<<<<", this.state.isSearch)
      if (this.state.isSearch) {
        console.debug(">>>>>>>>>> SEARCH CONTEXT <<<<<<<<<<<<", this.search_context)
        // const msg = "I didn't find an exact match for you. Here are the closest products I found.  \n\n **Swipe left above to see products.**"
        const msg = `${this.search_context.agent_initial_msg}\n\n **Swipe left above to see products.**`
        const agent_message = {"role":"assistant", "name": "seekr", "content": msg}
        this.addMessage(agent_message);
        this.isSearchMsgSet = true  // Hack to stop from rendering twice onComponentDidMount
      }
    }
    
    render() { 
      return (<div>{this.render_messaging()}</div>)} ;
  
} export default Messaging;
  
