import React, { useState } from 'react';
import { Toast, Row, Col, Card, Button } from 'react-bootstrap';

import Overlay from 'react-bootstrap/Overlay';
import Tooltip from 'react-bootstrap/Tooltip';

import { ArrowRightCircle, XCircleFill, XCircle, BoxArrowUp, BagPlus, BagPlusFill,  Bullseye } from 'react-bootstrap-icons'

import {SKR_WIDELY_AVAILABLE, SKR_MIN_HVT_VALUE, SKR_MIN_HVT_PERCENTAGE, SKR_ACT_TRACK, ISKR_ISSUED,
  SKR_OFFER_VIEW, 
  SKR_HVT_VIEW, 
  SKR_OFFER_ATB, 
  SKR_OFFER_CLICK     ,
  SKR_OFFER_PURCHASED ,
  SKR_ERROR           ,
  ISKR_ATTACHED       ,
  ISKR_CONVERSION     ,
  DMS_PRODUCT_VIEW    ,
  DMS_ATC_CLICK       ,
  DMS_CHECKOUT_CLICK  } from './skr-agent-events.js'

// Import Swiper styles
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Navigation, Parallax } from 'swiper'
import 'swiper/swiper-bundle.css';
import '../assets/styles/bootstrap.min.css';
import '../assets/styles/skr_comp_styles.css';
import flatSwipe from '../assets/icons/Flat-Swipe.gif'

class ProductViewer extends React.Component {

  /**
   * constructor
   *
   * @object  @props  parent props
   * @object  @state  component state
   */
  constructor(props) {

      super(props);

      this.state = {
        referenceProduct: this.props.referenceProduct,
        toggleParent: this.props.toggleView,
        getView: this.props.viewState,
        isDetailView: false,
        isTracked: false,
        isInBag: false,
        isNotification: false,
        msgNotification: "",
      }
      // this.swiperContainerRef = React.createRef();  // reference for swiper container to change size
      this.product = this.props.item
      this.offer = {}
      this.set_offer_features()
      this.columnRef = React.createRef()

  }

//   componentDidMount() {
//     window.addEventListener('resize', this.handleResize);
//     this.handleResize(); // Set initial size
//   }

//   componentWillUnmount() {
//     window.removeEventListener('resize', this.handleResize);
//   }

//   handleResize() {
//     if (this.columnRef.current && this.columnRef.current.offsetWidth) {
//       const columnWidth = this.columnRef.current.offsetWidth;
//       console.debug("handleResize: ", columnWidth)
//       console.debug("ref: ", this.columnRef.current)
//       this.setState({max_img_width: columnWidth}) 
//       // Use the column width to set the image size or perform any other logic
//   } else { return }
// }

  setMerchantName() {
    const item = this.props.item
    const name = (item.merchant) ? item.merchant_name : item.url_kv.pld

    if (item.merchant){ return (name) }
    else {
      var m = name
      m = m.replace(".", ' ')
      m = m.replace("com", '')
      m = m.replace("www", '')
      m = m.replace("WWW", '')
      m = m.replace(".", '')
      return(m)  
    }
  }

  get_offer_status() {
    const oc = this.offer
    if (!oc.isDiscounted) { return ("List Price at all Merchants") }
    if (oc.isHVT && oc.isLimitedOffer) {return ( `Limited Offer at ${oc.availability_count} of ${oc.total_available} merchants`) }
    if (oc.isHVT && oc.isWidelyAvailable) {return ( `Offer is widely available at ${oc.availability_count} merchants`) }
    if (oc.isHVT ) {return ( `Offer is available at ${oc.availability_count} of ${oc.total_available} merchants merchants`) }
  }

  set_offer_features() {

    const product = this.product
    const oc = this.props.response.offer_context
    const meta = this.props.meta

    this.merchant_name = this.setMerchantName()
    console.debug("RENDING MERCHANT OFFER >>>>>> ", this.merchant_name)
    console.debug("PRODUCT >>>>>> ", product)
    console.debug("OFFER   >>>>>> ", oc)
    console.debug("META    >>>>>> ", meta)

    // Set Product Features
    
    this.product_brand = (product.brand === null) ? product.brand: meta.brand
    this.product_name = (product.name === null) ? product.name :  meta.name
    this.product_brand_name = this.product_brand + " "+this.product_name
    this.product_price = "$"+product.price
    this.product_oldPrice = oc.isDiscounted ? "$"+(oc.msrp.toFixed(2)) : ""

    this.product_description = (product.description != null) ? product.description : meta.description
    this.product_discount_value = (product.offer_context.isDiscounted) ? product.offer_context.discount_value : 0
    this.product_imgs = (product.imgs != null && product.imgs.length != 0) ? product.imgs: meta.imgs
    // Set Product Logistics
    this.product_isInStock = (product.availability === "OutOfStock") ? "Out of Stock": product.availability 
    this.isProductAvailable = (product.availability === "InStock") ? true : false
    this.skuAttributes = (product.sku) ? " | "+product.sku : ""  

    // Set Merchant Product/Offer Features
    this.offer.isDiscounted = product.offer_context.isDiscounted
    this.offer.isHVT = product.offer_context.isHVT
    this.offer.isBestOffer = product.offer_context.isBestOffer
    this.offer.isDiscounted = product.offer_context.isDiscounted
    this.offer.product_price = (product.availability === "InStock")? "$"+product.price: "$"+product.price.toFixed(2)+" - Last Price" 
    this.offer.product_oldPrice = product.offer_context.isDiscounted ? "$"+(oc.msrp.toFixed(2)) : ""
    this.offer.discount_value = product.offer_context.discount_value
    this.offer.discount_percent = (product.offer_context.isDiscounted) ? (product.offer_context.discount_percentage).toString()+"%" : ""
    this.offer.savings_msg = (product.offer_context.isDiscounted) ? "Save $"+(product.offer_context.discount_value.toString()) : ""
    this.offer.rank = (product.offer_context.offer_rank) ? product.offer_context.offer_rank : ""
    this.offer.lable = (product.offer_context.isBestOffer) ? "RECOMMENDED" : "OPTION-"+product.offer_context.offer_rank.toString()
    this.offer.at_merchants = product.offer_context.availability_count.toString()
    this.offer.isLimitedOffer = (product.offer_context.isLimitedOffer) ? true : false
    this.offer.isWidelyAvailable = (product.offer_context.isWidelyAvailable) ? true : false

    if (this.isProductAvailable) {
      console.debug("OFFER CONTEXT: ", product.offer_context)
      if (product.offer_context.isLimitedOffer) {this.offer.title = "LIMITED OFFER"}
      else if (product.offer_context.isBestOffer) {this.offer.title = "BEST OFFER"} 
      else if (product.offer_context.isDiscounted) {this.offer.title = `DISCOUNTED OFFER`}
      else {this.offer.title = "NO OFFERS AVAILABLE"}
    }
    else { this.offer.title = "OUT OF STOCK" }

    // this.offer.status = (this.offer_isLimited) ? `Limited Offer at ${oc.availability_count} of ${oc.total_available} merchants` : (this.offer_isWidelyAvailable) ? `Offer Widely Available at ${oc.availability_count} merchants` : `Offer Available at ${oc.availability_count} merchants`
    this.offer.status = this.get_offer_status()
    this.offer.share_msg = this.get_share_msg()

    return
  }

  get_share_msg() {
    var msg = ""
    if (this.offer.isLimitedOffer) {
      msg = `My seekr shared this Limited Offer with you - Now ${this.offer.discount_percent}  ${this.offer.savings_msg}`
    }
    else if (this.offer_isWidelyAvailable) {
      msg = "My seekr shared this offer with you."}
    // console.debug("get_share_msg: ", msg)
    return msg
  }

  // TODO - NEED TO ADD SHARE ACTION
  render_actions() {  
    return (
      <div className="row align-middle skr-prod-actions-row">
          <div className="col-3 d-flex"> 
            {this.render_action_bag()}
          </div>
          <div className="col-3 d-flex"> 
            {this.render_action_track()}
          </div>
          <div className="col-3 d-flex"> 
            {this.render_action_share()}
          </div>
          <div className="col-3 d-flex " >
            {this.render_action_cto()}
          </div>
      </div>
    )
  }

  render_action_bag() {   
    if (this.state.isInBag) {
      return (<BagPlusFill className="skr-offer-action" onClick={this.onToggleBag} color="#FC2504" > </BagPlusFill>)  
    } else {
      return (<BagPlus className="skr-offer-action"  onClick={this.onToggleBag}> </BagPlus>)  
    }
  }

  render_action_track() {  
    if (this.state.isTracked) {
      return ( <Bullseye className="skr-offer-action" onClick={this.onToggleTracker} color="#FC2504" > </Bullseye> )  
    } else {
      return ( <Bullseye className="skr-offer-action" onClick={this.onToggleTracker}> </Bullseye> )  
    }
  }

  render_action_share() { 
    return ( <BoxArrowUp className="skr-offer-action" onClick={this.onShare}> </BoxArrowUp>)  
  }

  render_action_cto() {
    return (
      <div className="skr-site-link col-md-3 col-sm-6 col-xs-6 d-flex" >
        <a href={this.props.item.url} target="_blank" rel="noopener noreferrer">
          <span className="skr-actions-highlight">
              <ArrowRightCircle  title="To Merchants Site" onClick={() => this.props.onOfferClicked(this.props.item.url, this.props.item.ranking.savings_percentage, this.props.item.ranking.savings_value, this.state.isHVT)} />
          </span>
        </a>
      </div>
    );
  }

  renderPagination() {   
    if (this.state.isDetailView) { return (<></>) }
    else {
      return (
        <>
          <img className="img-responsive skr-swipe-indicator float-start" src={flatSwipe} /> 
        </>
      )
    }
  }
// Action/Event Handlers
  // Sharing is not working on Chrome.
  // TODO - NEED TO TEST WITH WORKING PAGES TO GET OG TAGS RENDERING IN MSG

  onShare = () => {
    const share_url = `http://127.0.0.1:5000/RPG-CLUSTER?cluster_id=${this.product.cluster_id}&agent_id=${this.agent_id}`
    
    const data = {title: this.product_brand_name, url: share_url, text: this.offer.share_msg}
    // console.debug("onShare: ", data)

    if (navigator.share) {
      navigator.share(data)
        .then(() => {
          this.props.onAgentEvent({event_id: ISKR_ISSUED, cluster_id: this.product.cluster_id, isHVT: this.offer.isHVT})
        })
        .catch((error) => {
          console.error('Error sharing:', error);
        });
      } else {
        console.debug('Web Share API not supported');
    }
  };

  // toggles View 
  onToggleView= () => { 
    this.setState(_state => ({
      isDetailView: !_state.isDetailView,
    }));
    this.state.toggleParent()
  }

  // toggles Watch 
  onToggleTracker= () => { 
    const isSet = !this.state.isTracked
    this.setState(_state => ({
      isTracked: !_state.isTracked,
      isNotification: true,
      msgNotification: (!_state.isTracked) ? 'Added Product Tracker' : 'Removed Product Tracker'
    }));
    this.props.onAgentEvent({event_id: SKR_ACT_TRACK, setTo: isSet, cluster_id: this.product.cluster_id, isHVT: this.offer.isHVT})
  }

  // toggles Bag 
  onToggleBag= () => { 
    const toggle = !this.state.isInBag
    this.setState(_state => ({
      isInBag: toggle,
      isNotification: true,
      msgNotification: (toggle) ? 'Added to your bag' : 'Removed from your bag'
    }));
  }

  // toggles Notifications 
  onToggleNotification= () => {
    this.setState(_state => ({
      isNotification: false,
      msgNotification: ''
    }));
  }
  
  
  render_notification() {
    if (!this.state.isNotification) {
      return(<></>)
    } else {  
      console.debug(">>>>>> RENDERING NOTIFICATION <<<<<<<", this.state.msgNotification)
      return (
        <div className="skr-notification-container d-flex justify-content-center align-items-center w-100" >
          <Row className="col-md-12 ">
            <Col xs={7}>
              <Toast onClose={() => this.onToggleNotification()} show={this.state.isNotification} delay={3000} autohide className="skr-notification">
                <Toast.Body> {this.state.msgNotification} </Toast.Body>
              </Toast>
            </Col>
          </Row>
        </div>
      );
    }
  }
  
  render_product_img() {  
    var product = this.props.item


    if((this.state.isDetailView) | (this.product_imgs == null)){
      return(<></>)
    } else if (!product.offer_context.isDiscounted) {

      return (
        <div className="skr-img-inset">  
          <img className="skr-prod-img-inset" src={this.product_imgs[0]}  onClick={this.onToggleView} />
        </div>
      )
    } else {
      return (
        <div className="skr-img-inset">  
          <img className="skr-prod-img-inset" src={this.product_imgs[0]}  onClick={this.onToggleView} />
            <span className="overlay skr-prod-tag-overlay">  {this.offer.discount_percent}</span>
        </div>

      )
    }
  }
  
  render_product_details() {  

    return (
      <div>
        <div className="skr-prod-title ">{this.product_brand}</div>
        <div className="skr-prod-name">{this.product_name}</div>
        <div className="skr-prod-description" >{this.product_description}</div>
      </div>
    )
  }

  render_product_pricing() {   
    // console.debug("render_product_pricing: ", this.props.item)
    if (this.product.offer_context.isDiscounted) {
      return (
      <div className="skr-prod-pricing col">
        <span>  {this.product_price}  </span>
        <span className="skr-prod-price-old">  {this.offer.product_oldPrice}</span>
      </div>
      )
  } else {
    return (
      <div className="skr-prod-pricing col">
        <span>  {this.product_price} (msrp) </span>
      </div>
      )
    }
  }

  render_product_SKU_details() {  
    
    if (!this.props.item) {return (<div></div>) }
    return (
      <div>
          <span >{this.offer.product_isInStock}</span>
          <span >{this.offer.skuAttributes}</span>
        </div>
    )
  }
  
  render_prod_delivery_details() { 
    const merchant = this.props.item.merchant
    const product = this.props.item

    if (merchant && product.availability === "InStock") {
      const deliveryCost = (merchant.delivery.cost === 0) ? "Free Delivery" : "$"+merchant.delivery.cost+" Delivery";
      const deliveryDays = (merchant.delivery.days) ? " - "+merchant.delivery.days+" Days" : "";
      return (
        <div>
          <span >{deliveryCost}</span>
          <span >{deliveryDays}</span>
        </div>
      )
    } else { return (<div>Out of Stock</div>) }
  }

  render_product_delivery() {  

    if ((this.state.isDetailView))  {
      return(<></>)
    } else {
      return (
        <div className="skr-prod-shipping">
          <div> {this.render_product_SKU_details()}       </div>
          <div> {this.render_prod_delivery_details()}  </div>
        </div>
      )
    }
  }

  render_product_img_swiper() {  
    // MAY NEED TO ALTER THIS LIKE THE PARENT SWIPER CLASS WRAPPERS AND SIZING

    if ((!this.state.isDetailView) | (this.product_imgs == null)) {
      return(<></>)
    } else {
        return (
          <div> 
              <button >
                  <XCircle className="skr-product-img-close" type="button" style={{ color: 'red' }} onClick={this.onToggleView}/>
              </button>

              <div className="swiper-container" ref={this.swiperContainerRef}>
                  <Swiper
                    className='skr-prod-detail-swiper'
                    style={{
                      "--swiper-navigation-color": "#555",
                      "--swiper-pagination-color": "#FC2504",
                    }}
                    speed={600}
                    parallax={true}
                    // pagination={{
                    //   clickable: true,
                    // }}
                    modules={[Parallax, Pagination, Navigation]}
                    nested={true}
                    slidesPerView={1}
                  >
                    {this.product_imgs.map((src, index) => (
                        <SwiperSlide key={index}> 
                          <div>
                            <img className="skr-prod-detail-img" src={src} /> 
                          </div>
                        </SwiperSlide>
                      ))}
                  </Swiper>
              </div>
          </div>
        )
      }  
    }

  render_offer_header() {
    const offerClass = this.offer.isLimitedOffer ? "skr-card-title-urgent col" : "skr-card-title col";
    return ( <div className="skr-card-title col"> {this.offer.title} </div>  )
  }

  render_offer_bottom_line() {
    const className = this.offer.isHVT ?  "skr-prod-bottom-line-urgent" : "skr-prod-bottom-line";
    if (this.product.offer_context.isDiscounted)  {
      return ( <div className={className}> SAVE ${this.product_discount_value} </div>  )
    }
    else { return (<></>) }
  }
  
  render_offer() {
    return (
      <div className="swiper-slide">
        <div className="skr-card">

          {/* ROW 1 - OFFER CLASSIFICATION */}
          <div className="row">
            <div className="col ">
                <div className="skr-card-title col"> {this.render_offer_header()} </div> 
              </div>
          </div>

          {/* ROW-2 OFFER STATUS - MERCHANT */}
          <div className="row skr-card-title">
            <div className="skr-offer-title-row col-md-4 col-sm-6 col-xs-12 col-gap">{this.offer.lable}</div>
            <div className="skr-offer-title-row col-md-4 col-sm-6 col-xs-12">{this.merchant_name}</div>
          </div>

          {/* ROW-3 PRODUCT DETAILS */}
          <div className="row">
              {/*  col-1 */}
              <div className="col-md-4 col-sm-6 col-xs-12 col-gap" ref={this.columnRef}>
                <div>{this.render_product_img()}</div>
                <div>{this.render_offer_bottom_line()}</div>
              </div>

              {/* col-2 */}
              <div className="col-md-8 col-sm-6 col-xs-12"> 
                <div> {this.render_product_details()} </div> 
                <div> {this.render_product_pricing()} </div> 
                <div> {this.render_product_delivery()} </div> 
              </div>
          </div>

          {/* ROW-4 PRODUCT ACTIONS */}
          <div className="row skr-actions">
              {/*  col-1 */}
              <div className="col-md-4 col-sm-6 col-xs-12 col-gap">
                <div style={{ width: '100%', height: '100%' }} >
                {this.render_notification()} </div>
              </div>

              <div className="col-md-8 col-sm-6 col-xs-12">
                <div> {this.render_actions()} </div>
              </div>
          </div >
        </div>
      </div>
    );
  }

  render() {
    if (this.state.isDetailView) {
      return ( <div> {this.render_product_img_swiper()} </div> )
    }
    else{
      return ( <div> {this.render_offer()}  </div>)
    }

  }
}

export default ProductViewer;


