import React, { useState } from 'react';
import { Toast, Row, Col, Card, Button } from 'react-bootstrap';

import Overlay from 'react-bootstrap/Overlay';
import Tooltip from 'react-bootstrap/Tooltip';

import {  CheckSquare, ArrowRightCircle, XCircleFill, XCircle, BoxArrowInDown, BoxArrowUp, BagPlus, BagPlusFill,  Bullseye } from 'react-bootstrap-icons'

import {SKR_WIDELY_AVAILABLE, SKR_MIN_HVT_VALUE, SKR_MIN_HVT_PERCENTAGE, SKR_ACT_TRACK, ISKR_ISSUED,
  SKR_OFFER_VIEW, 
  SKR_HVT_VIEW, 
  SKR_OFFER_ATB, 
  SKR_OFFER_CLICK     ,
  SKR_OFFER_PURCHASED ,
  SKR_ERROR           ,
  ISKR_ATTACHED       ,
  ISKR_CONVERSION     ,
  DMS_PRODUCT_VIEW    ,
  DMS_ATC_CLICK       ,
  DMS_CHECKOUT_CLICK  } from './skr-agent-events.js'

// Import Swiper styles
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Navigation, Parallax } from 'swiper'
import 'swiper/swiper-bundle.css';
import '../assets/styles/bootstrap.min.css';
import '../assets/styles/skr_comp_styles.css';
import flatSwipe from '../assets/icons/Flat-Swipe.gif'
import skr_logo from "../assets/branding/skr-logo-description.png"
import skr_mark from "../assets/branding/skr-marker@3x.png"
import {price_gauge} from './skr-price_gauge.js'

//  Shimano XTR Di2 Rear Derailleur (Medium Cage)
//  Garmin Edge 1030
//  Quarq DZero Power Meter Spider for Specialized
//  Quarq Power Meter Spider
//  Bontrager Meraj Women's Cycling Short

class SRP_Viewer extends React.Component {

  /**
   * constructor
   *
   * @object  @props  parent props
   * @object  @state  component state
   */
  constructor(props) {

      super(props);
      console.debug("########### LOADING SRP_Viewer ###########", this.props)

      this.srp = this.props.srp

      this.state = {
        referenceProduct: this.props.referenceProduct,
        toggleParent: this.props.toggleView,
        getView: this.props.viewState,
        isImgZoom: false,
        // isTracked: false,
        // isInBag: false,
        // isNotification: false,
        msgNotification: "",
        // isHVT : this.offer_context.isHVT
      }
      // this.swiperContainerRef = React.createRef();  // reference for swiper container to change size
      this.offer = {}
      this.set_offer_features()

  }

  componentDidUpdate() {
    // Update the cluster that is currently viewed
  }


  setMerchantName() {
    const item = this.props.item
    const name = (item.merchant) ? item.merchant_name : item.url_kv.pld

    if (item.merchant){ return (name) }
    else {
      var m = name
      m = m.replace(".", ' ')
      m = m.replace("com", '')
      m = m.replace("www", '')
      m = m.replace("WWW", '')
      m = m.replace(".", '')
      return(m)  
    }
  }

  get_offer_status() {
    const oc = this.offer
    // console.debug("get_offer_status: ", oc)
    if (!oc.isDiscounted) { 
      return ("List Price at all Merchants") }
    if (oc.isHVT && oc.isLimitedAvailable) {
      return ( `Limited Offer at ${oc.availability_count} of ${oc.total_available} merchants`) }
    
      if (oc.isHVT && oc.isWidelyAvailable) {
        return ( `Offer is widely available at ${oc.availability_count} merchants`) }
    if (oc.isHVT ) {
      return ( `Offer available at ${oc.availability_count} of ${oc.total_available} merchants`) }

    return ( `Offer available at ${oc.availability_count} merchants`)
  }

  get_share_msg() {
    var msg = ""
    if (this.offer.isLimited) {
      msg = `My Seekr found this limited offer for you - Now ${this.offer.discount_percent} off. Save $${this.offer.discount_value}.`
    }
    else if (this.offer.isHVT) {
      msg = `My Seekr found this deal for you - Now ${this.offer.discount_percent} off. Save $${this.offer.discount_value}.`}
    else if (this.offer.isWidelyAvailable) {
      msg = "Seekr shared this offer with you."}
    // console.debug("get_share_msg: ", msg)
    return msg
  }

  set_offer_features() {
    console.debug(">>>>>>>>>>>>>> SET OFFER FEATURES >>>>>>>>>>>>>>")
    const product = this.srp.product
    const offer = this.srp.cluster.offer_context
    const meta = this.srp.cluster.cluster_meta

    console.debug("PRODUCT: ", product)
    console.debug("OFFER:   ", offer)
    console.debug("META:    ", offer)

    // Set the cluster_id
    this.cluster_id = meta.cluster_id

    // Set Product Features
    this.product_brand = meta.brand
    this.product_name = meta.name
    this.product_brand_name = product.brand_name
    this.product_description = meta.description 
    this.product_imgs = (meta.imgs != null && meta.imgs.length != 0) ? meta.imgs : (product.imgs != null && product.imgs.length != 0)? product.imgs :  []

    this.offer.isClusterEmpty = offer.isClusterEmpty

    if (!offer.isClusterEmpty) {
      // Set Product Price / Savings
      this.product_price = "$"+offer.offer_price.toFixed(2) 
      this.product_oldPrice = offer.isDiscounted ? "$"+(offer.msrp.toFixed(2)) : ""
      
      // Set Product Logistics
      this.product_isInStock = (meta.availability === "OutOfStock") ? "Out of Stock": meta.availability 
      this.skuAttributes = (meta.sku) ? " | "+meta.sku : ""  

      // Set Offer Features
      this.offer.isHVT = offer.isHVT
      this.offer.isBestOffer = offer.isBestOffer
      this.offer.isDiscounted = offer.isDiscounted
      this.offer.discount = (offer.isDiscounted) ? (offer.discount_percentage) : 0
      this.offer.discount_value = offer.discount_value
      this.offer.discount_percent = (offer.isDiscounted) ? (offer.discount_percentage).toString()+"%" : ""
      this.offer.savings_msg = (offer.isDiscounted) ? "Save $"+(offer.discount_value.toString()) : ""
      this.offer.rank = (offer.offer_rank) ? offer.offer_rank+1 : ""
      this.offer.availability_count = offer.availability_count
      this.offer.total_available = offer.total_available
      this.offer.histogram = offer.histogram
      this.offer.price_box = offer.price_box
      this.offer.at_merchants = offer.availability_count.toString()
      this.offer.isLimitedOffer = offer.isLimitedAvailable
      this.offer.isWidelyAvailable = offer.isWidelyAvailable
      this.offer.status = offer.offer_messaging.status_msg
      this.offer.summary = this.offer.status
      this.offer.share_msg = this.get_share_msg()
    }

    return
  }


  render_actions() {  
    return (
      <div className="row align-middle skr-prod-actions-row">
          <div className="col-3 d-flex"> 
            {this.render_action_getCluster()}
          </div>
          <div className="col-3 d-flex"> 
            {this.render_action_track()}
          </div>
          <div className="col-3 d-flex"> 
            {this.render_action_share()}
          </div>
          <div className="col-3 d-flex " >
            {this.render_action_cto()}
          </div>
      </div>
    )
  }

  render_action_getCluster() {   
    return (<CheckSquare title="Select Product" className="skr-offer-action" onClick={this.props.onGetCluster} > </CheckSquare>)  
  }

  render_action_bag() {   
    if (this.state.isInBag) {
      return (<BagPlusFill className="skr-offer-action" onClick={this.onToggleBag} color="#FC2504" > </BagPlusFill>)  
    } else {
      return (<BagPlus title="Keep Seekr" className="skr-offer-action"  onClick={this.onToggleBag}> </BagPlus>)  
    }
  }

  render_action_track() {  
    if (this.state.isTracked) {
      return ( <Bullseye className="skr-offer-action" onClick={this.onToggleTracker} color="#FC2504" > </Bullseye> )  
    } else {
      return ( <Bullseye title="Add Tracker" className="skr-offer-action" onClick={this.onToggleTracker}> </Bullseye> )  
    }
  }

  render_action_share() { 
    return ( <BoxArrowUp title="Share" className="skr-offer-action" onClick={this.onShare}> </BoxArrowUp>)  
  }

  render_action_cto() {
    return (
      <div className="skr-site-link col-md-3 col-sm-6 col-xs-6 d-flex" >
          <span className="skr-actions-highlight">
          <ArrowRightCircle  title="To Merchants Site"  />
              {/* <ArrowRightCircle  title="To Merchants Site" onClick={() => this.props.onOfferClicked(this.props.item.url, this.props.item.ranking.savings_percentage, this.props.item.ranking.savings_value, this.state.isHVT)} /> */}
          </span>
      </div>
    );
  }
  // TODO - NEED TO GET URL FROM CLUSTER, NOT SET IN SRP
  // render_action_cto() {
  //   return (
  //     <div className="skr-site-link col-md-3 col-sm-6 col-xs-6 d-flex" >
  //       <a href={this.props.item.url} target="_blank" rel="noopener noreferrer">
  //         <span className="skr-actions-highlight">
  //         <ArrowRightCircle  title="To Merchants Site" onClick={() => this.props.onOfferClicked(this.props.item.url, this.props.item.ranking.savings_percentage, this.props.item.ranking.savings_value, this.state.isHVT)} />
  //             {/* <ArrowRightCircle  title="To Merchants Site" onClick={() => this.props.onOfferClicked(this.props.item.url, this.props.item.ranking.savings_percentage, this.props.item.ranking.savings_value, this.state.isHVT)} /> */}
  //         </span>
  //       </a>
  //     </div>
  //   );
  // }
  renderPagination() {   
    if (this.state.isImgZoom) { return (<></>) }
    else {
      return (
        <>
          <img className="img-responsive skr-swipe-indicator float-start" src={flatSwipe} /> 
        </>
      )
    }
  }
// Action/Event Handlers
  // Sharing is not working on Chrome.
  // TODO - NEED TO TEST WITH WORKING PAGES TO GET OG TAGS RENDERING IN MSG

  onShare = () => {
    const share_url = `http://127.0.0.1:5000/RPG-CLUSTER?cluster_id=${this.cluster_id}&agent_id=${this.agent_id}`
    
    const data = {title: this.product_brand_name, url: share_url, text: this.offer.share_msg}
    // console.debug("onShare: ", data)

    if (navigator.share) {
      navigator.share(data)
        .then(() => {
          this.props.onAgentEvent({event_id: ISKR_ISSUED, cluster_id: this.props.item.cluster_id, isHVT: this.state.isHVT})
        })
        .catch((error) => {
          console.error('Error sharing:', error);
        });
      } else {
        console.debug('Web Share API not supported');
    }
  };

  // toggles View 
  onToggleView= () => { 
    // console.debug("****** onToggleView ******")
    this.setState(_state => ({
      isImgZoom: !_state.isImgZoom,
    }));
    this.state.toggleParent()
  }

  // toggles Watch 
  onToggleTracker= () => { 
    const isSet = !this.state.isTracked
    this.setState(_state => ({
      isTracked: !_state.isTracked,
      isNotification: true,
      msgNotification: (!_state.isTracked) ? 'Added Product Tracker' : 'Removed Product Tracker.'
    }));
    this.props.onAgentEvent({event_id: SKR_ACT_TRACK, setTo: isSet, cluster_id: this.cluster_id, isHVT: this.offer.isHVT})
  }

  // toggles Bag 
  onToggleBag= () => { 
    const toggle = !this.state.isInBag
    this.setState(_state => ({
      isInBag: toggle,
      isNotification: true,
      msgNotification: (toggle) ? 'Added to your bag.' : 'Removed from your bag.'
    }));
  }

  // toggles Notifications 
  onToggleNotification= () => {
    this.setState(_state => ({
      isNotification: false,
      msgNotification: ''
    }));
  }
  
  render_search_error() {
    if (!this.state.isSearchError) { return (<div></div>)}
    return (
        <Toast onClose={() => this.setState({isSearchError: false})} show={this.state.isSearchError} delay={3000} autohide className="skr-search-error" >
          <Toast.Body >
              NO PRODUCTS MATCHED QUERY!
          </Toast.Body>
        </Toast>
    );
  }

  render_notification() {
    if (!this.state.isNotification) {
      return(<></>)
    } else {  
      console.debug(">>>>>> RENDERING NOTIFICATION <<<<<<<", this.state.msgNotification)
      return (
        <div className="skr-notification-container d-flex justify-content-center align-items-center w-100" >
          <Row className="col-md-12 ">
            <Col xs={7}>
              <Toast onClose={() => this.onToggleNotification()} show={this.state.isNotification} delay={3000} autohide className="skr-notification">
                <Toast.Body> {this.state.msgNotification} </Toast.Body>
              </Toast>
            </Col>
          </Row>
        </div>
      );
    }
  }
  
  render_product_details() {  

    return (
      <div>
        <div className="skr-prod-title">{this.product_brand}</div>
        <div className="skr-prod-name">{this.product_name}</div>
        <div className="skr-summary-description" >{this.product_description}</div>
      </div>
    )
  }

  render_product_pricing() {   
    if (this.offer.isDiscounted && this.offer.discount_value > 0)  {
      return (
        <div className="skr-prod-pricing col">
          <span>  {this.product_price}  </span>
          <span className="skr-prod-price-old">  {this.product_oldPrice}</span>
        </div>
        )
      } else {
        return (
          <div className="skr-prod-pricing col">
            <span>  {this.product_price}  </span>
          </div>
        )
      }
  }     

  render_product_img() {  
    console.debug("RENDERING PRODUCT IMAGE: isImgZoom ", this.state.isImgZoom)

    if((this.state.isImgZoom) | (this.product_imgs.length == 0)){return(<></>)} 
    // If there is a discount, render the discount
    // If there is an image, render the image
    
    if (this.offer.isDiscounted ) {
      console.debug("RENDERING DISCOUNT: ", this.offer.discount_percent)
      return (
        <div className="skr-img-inset">  
          <img className="skr-summary-img-inset" src={this.product_imgs[0]}  onClick={this.onToggleView} />
            <span className="overlay skr-prod-tag-overlay">  {this.offer.discount_percent}  </span>
        </div>
      )
    } else {
      console.debug("************* NOT RENDERING DISCOUNT: **************")
      return (
        <div className="skr-img-inset">  
          <img className="skr-summary-img-inset" src={this.product_imgs[0]}  onClick={this.onToggleView} />
        </div>
      )
    }
  }

  render_price_gauge() {
    // Gauge Parameters
    const gaugeHeight = 250;
    const gaugeWidth = 45;
    const baseColor = "#343541";
    const borderColor = "#979797";
    const {gradient, price_box} = price_gauge(this.offer)

    return (
      <div className="skr-histogram">
        <div className="skr-histogram-img-container"
              style={{
                height: `${gaugeHeight}px`,
                width: `${gaugeWidth}px`,
                backgroundColor: baseColor,
                border: `1px solid ${baseColor}`,
                background: gradient,
              }} >
        </div>
        <ul className="skr-histogram-overlay">
            {price_box.map(([pricePoint, numSellers]) => (
              <div key={pricePoint} className="skr-histogram-price-point">
                <span className="skr-histogram-price">{pricePoint}</span> 
                <span className="skr-histogram-sellers">{numSellers} </span>
                </div>
            ))}
        </ul>
      </div>
    );
  }

  render_offer_header() {
    const confidence = this.srp.confidence
    return ( <h1 className={"skr-summary-title col"}> Closest Product Matches <span className="confidence-text">({confidence})</span></h1> )
  }

  render_offer_bottom_line() {
    const className = this.offer.isHVT ?  "skr-summary-bottom-line-savings-urgent" : "skr-summary-bottom-savings-line";
    if (this.offer.isDiscounted && this.offer.discount_value > 0)  {
      return ( <h2 className={className}> {this.offer.savings_msg} </h2>  )
    }
  }
  
  render_header() {
    return ( <img className="skr-logo-header" src={skr_logo} /> ); }

  
  render_search_result() {
    console.debug("******* render_search_result *********", this.cluster)

    if (this.offer.isClusterEmpty) { return ( <div></div> ) }
    return (
      <div className="swiper-slide">
        <div className="skr-card">

          {/* ROW 1 - OFFER CLASSIFICATION */}
          <div className="row">
            <div className="col ">
                <div className="skr-card-title col"> {this.render_offer_header()} </div> 
              </div>
          </div>

          {/* ROW-3 PRODUCT DETAILS */}
          <div className="row">

              {/* col-3 */}
              <div className="col-md-4 col-sm-6 col-xs-12"> 
                <div> {this.render_product_img()} </div> 
                <div>{this.render_offer_bottom_line()}</div>

              </div>

              {/* col-2 */}
              <div className="col-md-4 col-sm-6 col-xs-12"> 
                <div> {this.render_product_details()} </div> 
                <div> {this.render_product_pricing()} </div> 
              </div>

              {/*  col-1 */}
              <div className="col-md-4 col-sm-6 col-xs-12">
                <div>{this.render_price_gauge()}</div>
              </div>
          </div>

          {/* ROW-2 OFFER STATUS - MERCHANT */}
          <div className="row skr-card-title">
            <div>
              <h2 className={"skr-summary-bottom-line col"}>
                <span> {this.offer.summary}</span>
              </h2>
            </div> 
            {/* <button className={`input-container-button active`} onClick={this.get_completion}>Select</button> */}

          </div>

          {/* ROW-4 PRODUCT ACTIONS */}
          <div className="row skr-actions">
              {/*  col-1 */}
              <div className="col-md-4 col-sm-6 col-xs-12 col-gap">
                <div style={{ width: '100%', height: '100%' }} > 
                {this.render_notification()} </div>
              </div>

              <div className="col-md-8 col-sm-6 col-xs-12">
                <div> {this.render_actions()} </div>
              </div>
          </div >
        </div>
      </div>
    );
  }
    

  render_product_image_swiper() {
    console.debug("******* CHECK PRODUCT IMG SWIPER *********", this.product_imgs)

    if ((!this.state.isImgZoom) | (this.product_imgs.length == 0)) {
      return(<></>)
    } else {
        console.debug("******* RENDER PRODUCT IMG SWIPER *********", this.product_imgs)

        return (
          <div> 
              <button >
                  <XCircle className="skr-product-img-close" type="button" style={{ color: 'red' }} onClick={this.onToggleView}/>
              </button>

              <div className="swiper-container" ref={this.swiperContainerRef}>
                  <Swiper
                    className='skr-prod-detail-swiper'
                    style={{
                      "--swiper-navigation-color": "#555",
                      "--swiper-pagination-color": "#FC2504",
                    }}
                    speed={600}
                    parallax={true}
                    // pagination={{
                    //   clickable: true,
                    // }}
                    modules={[Parallax, Pagination, Navigation]}
                    nested={true}
                    slidesPerView={1}
                  >
                    {this.product_imgs.map((src, index) => (
                        <SwiperSlide key={index}> 
                          <div>
                            <img className="skr-prod-detail-img" src={src} /> 
                          </div>
                        </SwiperSlide>
                      ))}
                  </Swiper>
              </div>
          </div>
        )
      }  
  }

  render() {
    if (this.state.isImgZoom) {
      return ( <div> {this.render_product_image_swiper()} </div> )
    }
    else{
      return ( <div> {this.render_search_result()}  </div>)
    }
  }
}

export default SRP_Viewer;


