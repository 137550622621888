
import React from 'react'

// Direct React component imports
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Navigation, Parallax } from 'swiper';
import 'swiper/swiper-bundle.css';
import 'swiper/swiper.min.css';
import '../assets/styles/skr_comp_styles.css';

import { ArrowRightCircle, ArrowsCollapse, BoxArrowUp, BoxArrowDown, InfoCircle, InfoCircleFill, BagPlus, BagPlusFill, Heart, HeartFill, Bullseye } from 'react-bootstrap-icons'


import ProductViewer from './skr-prod-viewer.js';
import Offer_Summary from './skr-offer-summary.js';
import SRP_Viewer from './skr-srp-viewer.js';


import {SKR_OFFER_VIEW, SKR_HVT_VIEW, SKR_OFFER_ATB, SKR_TRACK_PRODUCT,
  SKR_OFFER_CLICK     ,
  SKR_OFFER_PURCHASED ,
  SKR_ERROR           ,
  ISKR_ISSUED         ,
  ISKR_ATTACHED       ,
  ISKR_CONVERSION     ,
  DMS_PRODUCT_VIEW    ,
  DMS_ATC_CLICK       ,
  DMS_CHECKOUT_CLICK  } from './skr-agent-events.js'


class SwipeViewer extends React.Component {

    /**
     * constructor
     *
     * @object  @props  parent props
     * @object  @state  component state
     */
    constructor(props) {

        super(props);

        if (this.props.isSearch) { 
          console.debug("***** PARENT - SEARCH RESULTS ***** ", this.props.search_results) 
        }
        this.search_results = (this.props.isSearch)? this.props.search_results : null
  
        this.state = {
          isCluster: (this.props.isCluster) ? true : false,
          isSearch: (this.props.isSearch) ? true : false,
          screenMode: this.props.screenMode,  // light or dark
          isDetailView: false, 
          referenceProduct: this.props.referenceProduct,
          slideIndex: 0,
        }
        this.swiperContainerRef = React.createRef();  // reference for swiper container to change size
        this.maxSwiperWidth = '0px'  // default value

    }
    componentDidMount() {

      if (this.swiperContainerRef.current) {
        this.set_container_width()
      }
    }

  componentDidUpdate() {
    if (this.swiperContainerRef.current) {
      this.set_container_width()
    }
  }

  set_container_width(){
    // This is a hack to fix bug in Swiper that explodes out the container sizes at slide level.
    const swiperWrapper = this.swiperContainerRef.current.querySelector('.swiper-wrapper');
    const swiper_gap = 50

    if (this.swiperContainerRef.current) {
      const styles = window.getComputedStyle(swiperWrapper);
      const paddingLeft = parseFloat(styles.paddingLeft);
      const paddingRight = parseFloat(styles.paddingRight);

      const sww = this.swiperContainerRef.current.offsetWidth - paddingLeft - paddingRight - swiper_gap
      const sbw = (this.props.side_barRef.current != null)? this.props.side_barRef.current.offsetWidth : 0
      const width = (sww - sbw)+'px'
      
      swiperWrapper.style.maxWidth = width; // Set your desired max-width dynamically
      this.maxSwiperWidth = width  //save for later use in sub-swipers

      return 
    }
  }

  // Tracks the viewer mode of child swiper to ensure they load correct. 
  // Also sets container of parent to full size of viewport
  toggleView = () => {
    // console.debug("***** PARENT - TOGGLE VIEW ***** ")
    this.setState(_state => ({isDetailView: !_state.isDetailView}))
    this.props.toggleFullScreen()
  }

  // Tracks the viewer mode of child swiper to ensure they load correct. 
  getView = () => { return(this.state.isDetailView) }

  handleSlideChange = (swiper) => {
    // Handles callback from Swiper when slide changes when viewing 
    // search results.  This is used to set the cluster in the parent
    // component to update messaging.
    if (this.search_results === null) { return; }
    const activeSlideIndex = swiper.activeIndex;
    const activeSRP = this.search_results[activeSlideIndex];
    this.props.onSetCluster(activeSRP.cluster);
  }

  render_search_options() {

    return (
      <div className="swiper-container" ref={this.swiperContainerRef}>
        <Swiper
            slidesPerView={1}
            speed={600}
            parallax={true}
            spaceBetween={50}
            pagination={{ clickable: true }}
            modules={[Parallax, Pagination]}
            onSlideChange={this.handleSlideChange}
            onSwiper={(swiper) => this.swiper = swiper}
        >
            {this.search_results.map((srp, index) => {
                return (
                    <SwiperSlide key={index}> 
                        <SRP_Viewer 
                            srp={srp} 
                            // item={srp} 
                            // meta={srp.cluster_meta} 
                            // hvt={response.hvt} 
                            // referenceProduct={referenceProduct} 
                            // status={srp.status} 
                            viewState={this.getView} 
                            toggleView={this.toggleView} 
                            onAgentEvent={this.props.onAgentEvent} 
                            onOfferClicked={this.props.onOfferClicked} 
                            onGetCluster = {() => this.props.onGetCluster(srp.cluster.cluster_id)} 
                            maxSwiperWidth={this.state.maxSwiperWidth}
                        />
                    </SwiperSlide>
                );
            })}
        </Swiper>
    </div>
      );
  } 

  render_cluster() {
    const response = this.props.response            
    const items = this.props.response.topRanked
    const referenceProduct = this.state.referenceProduct

    // Check to ensure we have items to render
    if (items.length === 0) { return null; }

    return (
      <div className="swiper-container" ref={this.swiperContainerRef}>
        <Swiper
          slidesPerView={1}
          speed={600}
          parallax={true}
          spaceBetween= {50}
          pagination={{ clickable: true} }
          modules={[Parallax, Pagination]}
          onSwiper={(swiper) => this.swiper = swiper }
        >
          <SwiperSlide> 
            <Offer_Summary response={response} item= {items[0]} meta= {this.props.response.cluster_meta} rank={1} hvt={response.hvt} referenceProduct= {referenceProduct} status="Recommended" viewState={this.getView} toggleView={this.toggleView} onAgentEvent={this.props.onAgentEvent} onOfferClicked={this.props.onOfferClicked} maxSwiperWidth={this.state.maxSwiperWidth}/> 
          </SwiperSlide>

          {items.map((item, index) => (
            <SwiperSlide key={index}> 
              <ProductViewer response={response} item= {item} meta= {this.props.response.cluster_meta} rank={index + 1} hvt={response.hvt} referenceProduct= {referenceProduct} status={`Option-${index + 1}`} viewState={this.getView} toggleView={this.toggleView} onAgentEvent={this.props.onAgentEvent} onOfferClicked={this.props.onOfferClicked} maxSwiperWidth={this.state.maxSwiperWidth}/> 
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    );
  }

  render() {  
    if (this.state.isCluster)      {return ( <div> {this.render_cluster()} </div> );  }
    else if (this.state.isSearch)  {return ( <div> {this.render_search_options()} </div> );  }
  }
}
export default SwipeViewer;