// TO DO - MOVE TO COMBINED CONFIG.JS or SIMIALR
// # SKR-AGENT EVENTS
// Any changes must also be made in RPG_config.py
export const SKR_DEMAND_SIGNAL              = "SKR_DEMAND_SIGNAL"           //# Class of Signal used to track consumer product demand
export const SKR_PRODUCT_SIGNAL             = "SKR_PRODUCT_SIGNAL"          //# Class of signal that provides product details for a product instance
export const SKR_EVENTS_SIGNAL              = "SKR_EVENTS_SIGNAL"           //# Class of Signal that provides a set of discrete events of SKR Metrics - Following
export const SKR_AGENT_INITIALIZED          = "SKR_AGENT_INITIALIZED"        //# Consumer Install / Upgrade - New Agent has been Authorized by system
export const SKR_AGENT_RETIRED              = "SKR_AGENT_RETIRED"           //# Consumer has requested Agent to be Removed System
export const SKR_AGENT_ACTIVATED            = "SKR_AGENT_ACTIVATED"         //# Consumer has Activated Agent - Agent is active during session
export const SKR_AGENT_SLEEP                = "SKR_AGENT_SLEEP"             //# Consumer has requested Agent to sleep - NO ACTIVITY
export const SKR_HVT_VIEW                   = "SKR_HVT_VIEW"          //# View HVT Offer for Product Page
export const SKR_OFFER_VIEW                 = "SKR_OFFER_VIEW"              //# View Offer for Product Page
export const SKR_OFFER_CLICK                = "SKR_OFFER_CLICK"             //# Clicked on SKR-Offered Presented
export const SKR_OFFER_ATC                  = "SKR_OFFER_ATC"               //# Consumer added SKR-Offered product to cart
export const SKR_OFFER_BUY                  = "SKR_OFFER_BUY"               //# Consumer purchased SKR-Offered product
export const SKR_ACT_TRACK                  = "SKR_ACT_TRACK"           //# Consumer added Product Target to Track
export const SKR_ACT_ATC                    = "SKR_ACT_ATC"               //# Consumer added Product SKR-Cart
export const SKR_ERROR                      = "SKR_ERROR"                   //# Agent has logged an error
export const ISKR_ISSUED                    = "ISKR_ISSUED"                 //# Consumer referred to friend eith SKR or an iSKR (product Offer)
export const ISKR_ATTACHED                  = "ISKR_ATTACHED"               //# Consumer attached to iSKR from either Friend or Influencers
export const ISKR_CONVERSION                = "ISKR_CONVERSION"             //# Consumer referred to friend eith SKR or an iSKR (product Offer)
export const DMS_PRODUCT_VIEW               = "DMS_PRODUCT_VIEW"            //# Demand Signal - Consumer has Viewed a Product Page
export const DMS_PRODUCT_ATC                = "DMS_PRODUCT_ATC"             //# Demand Signal - Consumer Added a Product to Shopping Cart (Demand Signal)
export const DMS_PRODUCT_BUY                = "DMS_PRODUCT_BUY"             //# Demand Signal - Consumer has purchased a Product (Demand Signal)
export const PERF_CLUSTER_GET               = "PERF_CLUSTER_GET"            //# Performance Tracker - Get-Cluster Ropundtrip Performance
export const SKR_MIN_HVT_PERCENTAGE         = 20
export const SKR_MIN_HVT_VALUE              = 99
export const SKR_WIDELY_AVAILABLE           = 20
export const SKR_SELECT_CLUSTER             = "SKR_SELECT_CLUSTER"             //# Consumer has set a cluster



















