import React, { Component } from "react";
// import A9Card from './A9Card';
import ReactPlayer from 'react-player';
import { ArrowRightCircle, XCircleFill, XCircle, BoxArrowUp, BagPlus, BagPlusFill,  Bullseye } from 'react-bootstrap-icons'



const test_playlist = [
    {'url': 'https://youtu.be/USM2Qlt_Pn4&rel=0', 'title': 'The Scam of the century', 'description': 'Aside from losing over 1.13 million people, the United States is thought to have lost billions of dollars in pandemic relief fund fraud, according to the most recent Associated Press analysis, published this week. The analysis discovered that fraudsters took more than US$280 billion in relief cash, while another $123 billion was lost or misspent.  Combined, the loss represents 10 percent of the $4.2 trillion the U.S. government has so far disbursed in aid,” according to the report.  According to the analysis, this was the worst heist in U.S. history, with criminals stealing billions of dollars in government money intended to stabilize an economy in free fall.', 'channel': 'Patrick Boyle' },
    {'url': 'https://youtu.be/Pl6b-v-3HBw', 'title': 'Trump shouts, "Food for everyone!"', 'description': 'Alex Wagner shares reporting from the Miami New Times that despite promising out loud, on camera, "food for everyone," Donald Trump did not stick around at a restaurant campaign stop long enough for anyone to get food, illustrating a trait that is also getting in the way of Trumps ability to obtain legal counsel. Joyce Vance, former U.S. attorney, explains why this is a bad time in a bad case for Trump to have lawyer problems.', 'channel': 'Alex Wagner Tonight | MSNBC' },
    {'url': 'https://youtu.be/o5mhAHP3uZA', 'title': 'Matt Stephens Cervelo Caledonia-5 Long Term Review | Sigma Sports', 'description': "Since it's launch in 2020 Matt Stephens has been riding the Cervelo Caledonia-5 Road Bike. Built for long days in the saddle with a long-distance geometry and thanks to the 34mm tyre clearance you can ride the Caledonia-5 over a number of different terrains. The bike also ensures no watts are wasted courtesy of the compliance built into the carbon layup and no added gimmicks. This really is a premium, sleek-looking, versatile road bike that can be ridden all year round.  ​We sent Matt Stephens on a mission to put the bike through its paces and over the last six months he has really put it to the test. " , 'channel': 'Sigma Sports' },
    {'url': 'https://youtu.be/5UpsfXPSfiM', 'title': 'NEW 2022 Cervélo R5 | 703g Disc-Only Frame & 34mm Tyre Clearance', 'description': "Cervélo just this week have announced their very sleek 2022 R5 road bike. The R5 is lighter, more aero and a better climber than the R5 generations of old - think of it as their GC bike, if you like.  We actually first saw this bike being raced in this year's Tour de France by team Jumbo-Visma, with Wout Van Aert piloting the bike on a mountainous stage 8.  After extensive discussions and testing with the Jumbo-Visma team, the new R5 has been transformed from the all-round race bike into a versatile climbing bike with frameset that only weighs just over a kilo.   Warren Rossitor, BikeRadar’s Senior Road Tech Editor, talks us through all the new updates on the bike.    What do you think on the 2022 Cervélo R5? Are you happy to see a new bike focus on weight?", 'channel': 'BikeRadar' },
    ]

const player_config = { 
    youtube: {
      playerVars: { 
        showinfo: 0, 
        controls: 1,
        rel: 0,
        modestbranding: 1,
      }
    }
  }

    
class SKR_player extends Component {
  constructor(props) {
    super(props);

    console.log("skr_player constructor")
    this.state = {
      itemPlayingIndex: 0,
      itemPlaying: null,
      showOverlay: false,

    };

    this.playlist= test_playlist

  }

  onPause =(e)=>{
    alert("Pasued")
    this.setState({showOverlay:true})
  }
    onPlay =(e)=>{
    alert("play")
    this.setState({showOverlay:false})
  }
//   componentDidMount() {
//     const { a9_id } = this.props;
//     const playlist_data = getPlayList(a9_id);
//     const items = playlist_data ? [].concat(...playlist_data) : [];
//     this.setState({ items });
//   }

//   addToPlaylist = (e, story, a9_id) => {
//     addToPlayList(a9_id, story);
//     const triggerRefesh = this.state.searchRefresh === false ? true : false;
//     this.setState({ searchRefresh: triggerRefesh });
//   };

//   removeFromPlaylist = (e, story, a9_id) => {
//     removeFromPlayList(a9_id, story);
//     const triggerRefesh = this.state.searchRefresh === false ? true : false;
//     this.setState({ searchRefresh: triggerRefesh });
//   };

//   handleOnDuration = (duration) => {
//     const { itemPlaying, items } = this.state;
//     const indexToPlay = items.findIndex(i => i.id === itemPlaying.id);
      
//     items[indexToPlay].duration = duration;
//     itemPlaying.duration = duration;
//     this.setState({ items, itemPlaying });
//   };

//   handleOnEnded = () => {
//     this.playNext();
//   };

//   handleOnProgress = (progress) => {
//     const { stopList, itemPlaying } = this.state;
      
//     if (progress.played > 0.95 ) {
//       const indexToPlay = playlist.findIndex(i => i.id === itemPlaying.id);

//       console.log('Item Playing:', itemPlaying);
//       if (indexToPlay !== -1) {
//         const playItem = playlist[indexToPlay];
//         console.log('Item Playing Duration:', itemPlaying.duration);
//         console.log('playItem Duration:', playItem.duration);
        
//         if (playItem.author in stopList) {
//           const timeToJump = playItem.duration - progress.playedSeconds;
//           const trigger = stopList[playItem.author];
//           console.log('Item Duration:', itemPlaying.duration);
//           console.log('Progress Secs:', progress.playedSeconds);
//           console.log('Trigger @', trigger);
//           console.log('Jumping @', timeToJump);

//           if (timeToJump < trigger) {
//             this.playNext();
//           }
//         }
//       }
//     }
//   };

//   handlePlay = () => {
//     const { itemPlaying } = this.state;
//     const b = newPlayerState(itemPlaying.id, itemPlaying.url, true);
//     this.setState({ itemPlaying: b });
//   };

//   handlePlayPause = () => {
//     const { itemPlaying } = this.state;
//     const d = !itemPlaying.playing;
//     const b = newPlayerState(itemPlaying.id, itemPlaying.url, d);
//     this.setState({ itemPlaying: b });
//   };

//   handlePause = () => {
//     const { itemPlaying } = this.state;
//     const b = newPlayerState(itemPlaying.id, itemPlaying.url, false);
//     this.setState({ itemPlaying: b });
//   };

//   markAsPlayedInPlaylist = (item) => {
//     const { a9_id } = this.props;
//     markAsPlayed(a9_id, item);
//   };

//   playItem = (item, prev = false) => {
//     const { items } = this.state;
//     const indexToPlay = items.findIndex(i => i.id === item.id);

//     if (indexToPlay >= 0) {
//       const nextItem = items[indexToPlay];
//       if (nextItem.video != null) {
//         const playerState = newPlayerState(nextItem.id, nextItem.video.url, true);
//         this.setState({ itemPlayingIndex: indexToPlay, itemPlaying: playerState });
//       }
//     }
//   };

//   playRequest = (item) => {
//     const { itemPlaying } = this.state;
//     if (itemPlaying.playing) {
//       this.markAsPlayedInPlaylist(itemPlaying);
//     }

//     const { playlist_data } = this.state;
//     const nextItemIndex = playlist_data.findIndex(i => i.id === item.id);
//     const nextItem = playlist_data[nextItemIndex];
//     this.playItem(nextItem);
//   };

//   renderImage = (story) => {
//     if (story.video != null && story.video.img != null) {
//       return (
//         <div style={{backgroundImage: `url("${story.video.img}")`, width:'100%', height:'200px', 
//           justifyContent:'center', backgroundPosition:'center center', backgroundSize:'cover',
//           display:'flex', alignItems:'center', cursor:'pointer'}} />
//       );
//     } else if (story.images != null && story.images.length != 0) {
//       const image = story.images[0];
//       return (
//         <Image className="a9-view-feed-image" src={image} width="100%" height="200px"/>
//       );
//     } else {
//       return (<div></div>);
//     }
//   };

  play_next_item= () => {
    console.log("play_next_item")
    const nextIndex = this.state.itemPlayingIndex + 1
    if (nextIndex < this.playlist.length) {
      this.setState({itemPlayingIndex: nextIndex})
    }
  }
  
  render_player() {
    console.log("render_player")
    const item = this.playlist[this.state.itemPlayingIndex]
    console.log("rendering video", item)
  
    return (
      <div>
        <div className="skr-player-container">
            <ReactPlayer 
            key={'vid-fixed-player'}
            className='react-player'
            config={player_config}
            url={item.url}
            controls={true}
            light={false}
            playing={true}
            width={'100%'}
            height={'100%'}
            pip={true}
            volume={0.1}
            muted={true}
            loop={false}  
            stopOnUnmount={false}  
            playbackRate={1.0}              
            />
        </div>
        <div>
            <button onClick={this.play_next_item}>Next</button>
        </div>
    </div>
    )
  }

  render() { return( <div> {this.render_player() }  </div>) } 

}

export default SKR_player;
